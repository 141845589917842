import { z } from 'zod';

export const NameSchema = z.object({
  firstName: z
    .string()
    .transform((value) => value.trim())
    .pipe(
      z
        .string({ required_error: 'First name is required' })
        .min(1, 'First name is required.')
        .max(32, 'Name is too long - max 32 characters. Please contact support if your name is longer'),
    ),
  lastName: z
    .string()
    .transform((value) => value.trim())
    .pipe(
      z
        .string({ required_error: 'Last name is required' })
        .min(1, 'Last name is required.')
        .max(32, 'Name is too long - max 32 characters. Please contact support if your name is longer'),
    ),
  middleName: z
    .string()
    .transform((value) => value.trim())
    .pipe(
      z
        .string()
        .max(32, 'Name is too long - max 32 characters. Please contact support if your name is longer')
        .or(z.literal(''))
        .optional(),
    ),
});

export type NameFields = z.infer<typeof NameSchema>;
