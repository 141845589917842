import { useCallback, useEffect, useRef } from 'react';

import env from '@shared/config';
import { StorageKey } from '@shared/storage';

import * as Sentry from '@sentry/react';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
declare global {
  interface Window {
    RISKX?: {
      go: (location: string) => void;
      setSid: (sid: string) => void;
    };
  }
}

const SCRIPT_ID = 'riskified-script';
const POLLING_INTERVAL_MS = 200;
const MAX_POLLING_ATTEMPTS = 15; // 3 seconds

function useRiskified() {
  const riskifiedUrl = env.RISKIFIED_URL;
  /**
   * The SHOP is the root domain as configured by Riskified in https://app.riskified.com/app/settings/account/technical
   * Note: this is just a string and does not need to reflect to domain that the service is being called from
   */
  const shop = env.RISKIFIED_SHOP;
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const isApiReadyPromiseRef = useRef<Promise<void>>();
  const pollingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const baseUrl = `${riskifiedUrl}?shop=${shop}`;

  const updateSid = useCallback(() => {
    const newSid =
      `RISKIFIED_SID_${window.crypto?.randomUUID?.()}_${String(Date.now())}` || `RISKIFIED_SID_${String(Date.now())}`;
    window.sessionStorage.setItem(StorageKey.RISKIFIED_SID, newSid);
    if (window.RISKX && typeof window.RISKX.setSid === 'function') {
      window.RISKX.setSid(newSid);
    }
    return newSid;
  }, []);

  const getSid = useCallback(() => {
    let sid = window.sessionStorage.getItem(StorageKey.RISKIFIED_SID);
    if (!sid) {
      sid = updateSid();
    }
    return sid;
  }, [updateSid]);

  useEffect(() => {
    const isRiskifiedEnabled = isFeatureEnabled(AppFeature.Riskified);

    const cleanup = () => {
      if (pollingTimeoutRef.current) {
        clearTimeout(pollingTimeoutRef.current);
        pollingTimeoutRef.current = null;
      }
      const script = document.getElementById(SCRIPT_ID);
      if (script) {
        script.remove();
      }
    };

    if (!isRiskifiedEnabled) {
      cleanup();
      if (isApiReadyPromiseRef.current) {
        isApiReadyPromiseRef.current = undefined;
      }
      return cleanup;
    }

    if (isApiReadyPromiseRef.current) {
      return cleanup;
    }

    isApiReadyPromiseRef.current = new Promise<void>((resolve, reject) => {
      // Poll until the Riskified API (window.RISKX) is available, as script.onload
      const pollForApi = (attempt = 1) => {
        if (window.RISKX && typeof window.RISKX.setSid === 'function') {
          resolve();
        } else if (attempt < MAX_POLLING_ATTEMPTS) {
          pollingTimeoutRef.current = setTimeout(() => pollForApi(attempt + 1), POLLING_INTERVAL_MS);
        } else {
          const error = new Error(`Riskified API timed out after ${MAX_POLLING_ATTEMPTS} attempts.`);
          Sentry.captureException(error);
          reject(error);
          isApiReadyPromiseRef.current = undefined;
        }
      };

      let script = document.getElementById(SCRIPT_ID) as HTMLScriptElement | null;

      if (script) {
        if (window.RISKX && typeof window.RISKX.setSid === 'function') {
          resolve();
        } else {
          pollForApi(); // Script exists but API not ready, start polling
        }
      } else {
        script = document.createElement('script');
        script.id = SCRIPT_ID;
        script.async = true;
        script.src = `${baseUrl}&sid=${getSid()}`;
        script.setAttribute('data-test', 'riskified');

        script.onload = () => {
          pollForApi(); // Script loaded, start polling for API readiness
        };

        script.onerror = (event) => {
          const error = new Error('Failed to load Riskified script (onerror)');
          if (event instanceof Event) {
            (error as any).eventTarget = event.target?.toString();
          } else {
            (error as any).errorEventDetails = event;
          }
          Sentry.captureException(error);
          reject(error);
          isApiReadyPromiseRef.current = undefined;
        };

        document.body.appendChild(script);
      }
    });

    isApiReadyPromiseRef.current
      .then(() => {
        if (window.RISKX && typeof window.RISKX.setSid === 'function') {
          const currentSid = getSid();
          window.RISKX.setSid(currentSid);
        } else {
          const error = new Error('Riskified: Inconsistent state - API ready promise resolved but API unavailable.');
          Sentry.captureException(error);
          isApiReadyPromiseRef.current = undefined;
        }
      })
      .catch((error) => {
        if (
          !(
            error instanceof Error &&
            (error.message.includes('Riskified API timed out') ||
              error.message.includes('Failed to load Riskified script'))
          )
        ) {
          Sentry.captureException(
            error instanceof Error
              ? error
              : new Error(`Riskified: Unexpected error in promise chain: ${String(error)}`),
          );
        }
      });

    return cleanup;
  }, [baseUrl, isFeatureEnabled, getSid, updateSid]);

  // Return the promise that resolves when the API is ready, plus SID helpers
  return { riskifiedReady: isApiReadyPromiseRef.current, getSid, updateSid };
}

export { useRiskified };
