import React from 'react';

import { GreenIDFrame as BaseGreenIDFrame, GreenIDFrameProps } from '@global-components/GreenIDFrame';

import { api } from '@shared/api';
import { SwyftxError } from '@shared/error-handler';
import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

type Props = Pick<GreenIDFrameProps, 'onSuccess' | 'onCancel' | 'onError'>;

export const GreenIDFrame: React.FC<Props> = observer(({ onSuccess, onCancel, onError }) => {
  const { userProfile } = UserStore.useUserStore;
  const greenIdRef = userProfile?.verification?.greenid.id;

  const fetchToken = async (ref: string): Promise<string> => {
    const result = await api.endpoints.getNewGreenIDToken({ query: { id: ref } });
    if (result.data.id !== ref) {
      throw new SwyftxError('Fetch GreenID Token', 'GreenID ref mismatch');
    }
    return result.data.token;
  };

  const storeResult = async (ref: string): Promise<void> => {
    await api.endpoints.setGreenIDInfo({
      data: {
        verification: {
          id: ref,
        },
      },
    });
  };

  return (
    <BaseGreenIDFrame
      greenIdRef={greenIdRef}
      fetchToken={fetchToken}
      storeResult={storeResult}
      onSuccess={onSuccess}
      onCancel={onCancel}
      onError={onError}
    />
  );
});
