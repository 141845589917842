import React from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { InputProps } from '@swyftx/aviary/atoms/Input/Input.styles';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { FieldError } from 'react-hook-form';

type Props = {
  label: string;
  hint?: string;
  required?: boolean;
  error?: FieldError;
} & Omit<InputProps, 'error'>;

const FormInput: React.FC<Props> = ({ label, hint, required, error, ...props }) => (
  <FlexLayout direction='column' spacing={4} className='w-full'>
    <FlexLayout spacing={0} alignItems='start' justifyContent='start'>
      <FlexLayout spacing={4}>
        <Body size='small' weight='bold' color='primary'>
          {label}
        </Body>
        {hint && (
          <Body size='small' className='italic'>
            {hint}
          </Body>
        )}
      </FlexLayout>
      {required && (
        <Body size='small' weight='bold' color='error'>
          *
        </Body>
      )}
    </FlexLayout>

    <Input {...props} error={!!error?.message?.length} />
    {error && (
      <Body size='small' color='error'>
        {error.message}
      </Body>
    )}
  </FlexLayout>
);

export { FormInput };
