import React, { ReactNode } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

type Props = {
  heading: string;
  description: string;
  children?: ReactNode;
};

export const BaseReverificationStep: React.FC<Props> = ({ heading, description, children }) => (
  <FlexLayout direction='column' spacing={32}>
    <FlexLayout direction='column' spacing={8}>
      <Heading size='h3' color='primary' weight='light'>
        {heading}
      </Heading>
      <Body color='secondary'>{description}</Body>
    </FlexLayout>
    {children}
  </FlexLayout>
);
