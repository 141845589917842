import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Label } from '@swyftx/aviary/atoms/Label';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { EditInSquare } from '@swyftx/aviary/icons/outlined/EditInSquare';

type Props = {
  label: string;
  hint?: string;
  value: string;
  onEdit?: () => void;
};

export const DetailPreview: React.FC<Props> = ({ label, hint, value, onEdit }) => (
  <FlexLayout direction='row' alignItems='center' spacing={12} className='h-[44px]'>
    <FlexLayout direction='row' justifyContent='space-between' alignItems='center' spacing={12} className='flex-grow'>
      <FlexLayout direction='column'>
        <Label className='text-10 uppercase'>{label}</Label>
        {hint && <Label className='text-8'>{hint}</Label>}
      </FlexLayout>
      <Body weight='bold'>{value}</Body>
    </FlexLayout>
    <Button layout='icon' leadingIcon={<EditInSquare />} variant='ghost' onClick={onEdit} />
  </FlexLayout>
);
