import { Auth, Profile, TradePriceHistory, UserSettings } from '@shared/api';
import { Asset } from '@shared/api/@types/markets';
import { UserBalances, UserStatistics } from '@shared/api/@types/user';
import {
  AccountStatusEnum,
  EmailVerifyStatusEnum,
  EntityTypeEnum,
  FiatCodeEnum,
  FiatIdEnum,
  GreenIdStatusEnum,
  KycStatusEnum,
  PhoneVerifyStatusEnum,
  ReverificationStatusEnum,
  TimesEnum,
} from '@shared/enums';
import { SwyftxError, isReCaptchaError } from '@shared/error-handler';
import { assetService } from '@shared/services';
import entityService from '@shared/services/entityService';
import storage, { StorageKey } from '@shared/storage';

import AuthenticationService from '@services/AuthenticationService';

import * as Sentry from '@sentry/react';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { action, autorun, observable, runInAction } from 'mobx';
import { DEFAULT_TRADINGVIEW_PRICE_SIDE } from 'src/lib/trade/components/TradingViewWidget/types/tradingViewGlobalState';
import Cookies from 'universal-cookie';

import {
  Affiliate,
  ChartPreference,
  EntityAccount,
  EntityMember,
  UserStateType,
  UserStoreSchema,
} from './@types/userTypes';

const initialValues: UserStateType = {
  userAuth: undefined,
  previousUserAuth: undefined,
  userProfile: undefined,
  previousUserProfile: undefined,
  userBaseCurrency: FiatIdEnum.USD,
  userCountryCurrency: FiatIdEnum.USD,
  userCountryCurrencyCode: FiatCodeEnum.USD,
  balances: {},
  tradePriceHistory: {},
  chartPreferences: {
    interval: '240',
    type: 1,
    priceSide: DEFAULT_TRADINGVIEW_PRICE_SIDE,
    showMarks: true,
    indicators: [],
  },
  recaptchaToken: '',
  userId: '',
  isLoggedIn: false,
  entityUuid: undefined,
  entityAccounts: [],
  entityMembers: [],
  entityColorIndex: undefined,
  affiliate: {
    isLoadingState: true,
    isErrorState: false,
  },
  userStatistics: undefined,
  scopeArray: [],
  stakingInfo: undefined,
  assetsToDust: [],
};

const store: UserStoreSchema = observable({
  /* observables */
  ...initialValues,

  setAuth: action((auth?: Auth, isEntity?: boolean) => {
    store.userAuth = auth;
    store.userId = '';

    if (auth) {
      if (auth.id_token) {
        // extract user uuid
        const decoded = jwtDecode<JwtPayload>(auth.id_token);
        store.userId = decoded.sub || '';
      }
      // set logged in
      store.isLoggedIn = true;

      // save to storage for remember me feature
      if (!isEntity) {
        storage.setItem(StorageKey.PERSONAL_ACCESS_TOKEN, auth.access_token);
      }

      storage.setItem(StorageKey.ACCESS_TOKEN, auth.access_token);
    } else {
      store.isLoggedIn = false;
      storage.removeItem(StorageKey.ACCESS_TOKEN);
    }
  }),

  setPreviousAuth: action((auth?: Auth) => {
    store.previousUserAuth = auth;
  }),

  setOtcOptIn: action((optIn: boolean) => {
    if (store.userProfile?.accountStatus) {
      store.userProfile.accountStatus.tradeOnBehalf = optIn ? 1 : 0;
    }
  }),

  setUserProfile: action((profile: Profile) => {
    store.userProfile = profile;
    storage.setItem(StorageKey.EMAIL, profile.email);

    // set domain wide cookie for all applications on swyftx.com to access logged in user
    const data = {
      token: profile.intercom?.uuid,
      user: {
        firstName: profile.name?.first,
      },
    };
    const host = window.location.host.split('.');
    host.shift();
    const domain = host.join('.').trim() || 'localhost';
    const cookies = new Cookies();
    cookies.set('swyftx_auth', data, { path: '/', domain });
  }),

  setPreviousUserProfile: action((profile: Profile) => {
    store.previousUserProfile = profile;
  }),

  setRecaptchaToken: action((token: string) => {
    store.recaptchaToken = token;
  }),

  setUserCurrency: action((currencyId: Asset['id'], assetCode: Asset['code']) => {
    if (store.userProfile) store.userProfile.currency = { code: assetCode, id: currencyId };
  }),

  setFavourites: action((assetId: Asset['id'], isFavourite: boolean) => {
    if (store.userProfile) store.userProfile.userSettings.favouriteAssets[assetId] = isFavourite;
  }),

  setUserSettings: action((userSettings: UserSettings) => {
    if (store.userProfile) store.userProfile.userSettings = userSettings;
  }),

  resetUserProfile: action(() => {
    store.userProfile = undefined;
    store.userAuth = undefined;
    store.baseAsset = undefined;
    store.balances = {};
    store.userStatistics = undefined;
    store.recaptchaToken = '';
  }),

  resetPreviousUserProfile: action(() => {
    store.previousUserProfile = undefined;
  }),

  isKyc1Complete: (): boolean => store?.userProfile?.kyc?.KYC1 === KycStatusEnum.PASSED,

  isKyc0Required: (): boolean => store?.userProfile?.kyc?.KYC0 === KycStatusEnum.REQUIRED,

  isKyc1Required: (): boolean => store?.userProfile?.kyc?.KYC1 === KycStatusEnum.REQUIRED,

  sourceOfWealthStatus: (): KycStatusEnum => store?.userProfile?.kyc?.KYC2 ?? KycStatusEnum.UNREQUESTED,

  getUniqueIdentifier: (): string => {
    // If there is no profile we can't derive a unique id
    if (!store.userProfile) return '';

    // The intercom uuid is the best way to get this unique ID. return it if it exists
    if (store.userProfile.intercom?.uuid) return store.userProfile.intercom?.uuid;

    // The next best uniqueID is the email
    if (store.userProfile.email) return store.userProfile.email;

    // The last best uniqueID is their name. This should also be unique on the device
    if (store.userProfile.name.entity || store.userProfile.name.first || store.userProfile.name.last) {
      return `${store.userProfile.name.entity} ${store.userProfile.name.first} ${store.userProfile.name.last}`;
    }

    // If the above are not available, we were unable to derive a unique ID
    return '';
  },

  isKyc2Required: (): boolean =>
    store?.userProfile?.kyc?.KYC2 !== undefined &&
    [KycStatusEnum.REQUIRED, KycStatusEnum.STARTED].includes(store?.userProfile?.kyc?.KYC2),
  isKyc2Complete: (): boolean => store?.userProfile?.kyc?.KYC2 === KycStatusEnum.PASSED,

  getEntityMember: (): EntityMember | undefined => {
    if (!store.isEntity()) return undefined;

    return store.entityMembers.find((e) => e.uuid === store.previousUserProfile?.intercom?.uuid);
  },
  getAccountStatus: (): AccountStatusEnum => {
    const phoneUnverified = Boolean(store.userProfile?.verification?.phone) === false;
    const emailUnverified = Boolean(store.userProfile?.verification?.email) === false;
    const greenIdComplete = store.userProfile?.verification?.greenid.status === GreenIdStatusEnum.VERIFIED;
    const greenIdVerifiedUnderage =
      store.userProfile?.verification?.greenid.status === GreenIdStatusEnum.VERIFIED_UNDERAGE;

    let isRestricted = store.isKyc0Required() || store.isKyc1Required();

    /** entities don't have phones or emails */
    if (!store.isEntity()) {
      isRestricted = isRestricted || phoneUnverified || emailUnverified;
    }

    if (greenIdComplete) {
      if (greenIdVerifiedUnderage) return AccountStatusEnum.BRONZE_RESTRICTED;

      /** NZ users don't have silver status (Bronze, Gold, Diamond only) */
      if (store.isNZ()) {
        if (store.isKyc1Complete()) {
          // At least Gold for NZ users
          if (store.isKyc2Complete()) {
            // At least Diamond
            return isRestricted ? AccountStatusEnum.DIAMOND_RESTRICTED : AccountStatusEnum.DIAMOND;
          }
          return isRestricted ? AccountStatusEnum.GOLD_NZ_RESTRICTED : AccountStatusEnum.GOLD_NZ;
        }
      } else {
        // At least Silver
        if (store.isKyc1Complete()) {
          // At least Gold
          if (store.isKyc2Complete()) {
            // At least Diamond
            return isRestricted ? AccountStatusEnum.DIAMOND_RESTRICTED : AccountStatusEnum.DIAMOND;
          }
          return isRestricted ? AccountStatusEnum.GOLD_RESTRICTED : AccountStatusEnum.GOLD;
        }
        return isRestricted ? AccountStatusEnum.SILVER_RESTRICTED : AccountStatusEnum.SILVER;
      }
    }

    return greenIdVerifiedUnderage ? AccountStatusEnum.BRONZE_RESTRICTED : AccountStatusEnum.BRONZE;
  },

  getHumanReadableKycStatus: (kyc?: KycStatusEnum): string => {
    switch (kyc) {
      case KycStatusEnum.FAILED:
        return 'Failed';
      case KycStatusEnum.PASSED:
        return 'Passed';
      case KycStatusEnum.REQUIRED:
        return 'Required';
      case KycStatusEnum.STARTED:
        return 'Started';
      case KycStatusEnum.UNREQUESTED:
        return 'Unrequested';
      case KycStatusEnum.VOLUNTEERED:
        return 'Volunteered';
      default:
        Sentry.captureException(`Unknown KYC status: ${kyc}`);
        return '';
    }
  },

  isAU: (): boolean => store.userProfile?.countryCurrency?.code === FiatCodeEnum.AUD,
  isNZ: (): boolean => store.userProfile?.countryCurrency?.code === FiatCodeEnum.NZD,

  isUserVerified: (): boolean => {
    if (store.isEntity()) {
      return entityService.isEntityVerified();
    }

    return (
      store.userProfile?.verification?.greenid?.status === GreenIdStatusEnum.VERIFIED &&
      store.userProfile?.verification?.email === EmailVerifyStatusEnum.VERIFIED &&
      store.userProfile?.verification?.phone === PhoneVerifyStatusEnum.VERIFIED &&
      (!store.isNZ() || store.isKyc1Complete())
    );
  },
  userHasCompletedOnboarding: (): boolean => {
    const hasNotCompletedOnboarding = [GreenIdStatusEnum.IN_PROGRESS, GreenIdStatusEnum.NOT_STARTED];
    const greenIdStatus = store.userProfile?.verification?.greenid?.status;
    if (store.isEntity()) {
      return entityService.isEntityVerified();
    }
    return greenIdStatus !== undefined && !hasNotCompletedOnboarding.includes(greenIdStatus);
  },
  userHasPendingIdVerification: (): boolean =>
    !store.isEntity() && store.userProfile?.verification?.greenid?.status === GreenIdStatusEnum.PENDING_REVIEW,

  reverificationWindowDays: (): number => {
    const reverification = store.userProfile?.accountStatus?.reverification ?? null;
    return reverification ? Math.floor(reverification.window / TimesEnum.DAY) : 0;
  },
  reverificationDaysRemaining: (): number => {
    const reverification = store.userProfile?.accountStatus?.reverification ?? null;
    switch (reverification?.status) {
      case ReverificationStatusEnum.REQUESTED:
      case ReverificationStatusEnum.IN_PROGRESS:
        const timeRemaining = Math.max(0, reverification.requested + reverification.window - Date.now());
        return Math.floor(timeRemaining / TimesEnum.DAY);
      case ReverificationStatusEnum.LAPSED:
      case ReverificationStatusEnum.VERIFIED:
      case ReverificationStatusEnum.FAILED:
      case undefined:
        return 0;
    }
  },
  reverificationRequiredBy: (): number => {
    const reverification = store.userProfile?.accountStatus?.reverification ?? null;
    switch (reverification?.status) {
      case ReverificationStatusEnum.REQUESTED:
      case ReverificationStatusEnum.IN_PROGRESS:
      case ReverificationStatusEnum.LAPSED:
        return reverification.requested + reverification.window;
      case ReverificationStatusEnum.VERIFIED:
      case ReverificationStatusEnum.FAILED:
      case undefined:
        return 0;
    }
  },

  canTransferCrypto: (): boolean => {
    const { userProfile, getAccountStatus } = store;
    const status = getAccountStatus();

    // if we explicitly don't have the kyc1 flag, allow silver
    if (userProfile?.metadata?.cryptoRequiresKyc1 === false && status >= AccountStatusEnum.SILVER) {
      return true;
    }

    return status >= AccountStatusEnum.GOLD;
  },
  isEntity: (): boolean =>
    !!(
      store.userProfile && // if user profile exists (I.e. not logged out)
      !(
        (
          store.userProfile?.entityDetails &&
          store.userProfile?.entityDetails.entityType &&
          store.userProfile?.entityDetails.entityType === 'PERSONAL'
        ) // if account is of entity type of personal
      )
    ),

  setBalances: action((balances: UserBalances) => {
    store.balances = balances || {};
  }),

  setTradePriceHistory: action((tradePriceHistory: TradePriceHistory) => {
    store.tradePriceHistory = tradePriceHistory || {};
  }),

  getBalance: (asset: Asset | Asset['id']): string => {
    const fullAsset = assetService.getAsset(asset);
    if (fullAsset && store.balances[fullAsset.id]) {
      return store.balances[fullAsset.id].availableBalance ?? '0';
    }
    return '0';
  },

  setRefreshRecaptcha: action((refreshRecaptcha: boolean) => {
    store.refreshRecaptcha = refreshRecaptcha;
  }),

  setChartPreferences: action((preferences: ChartPreference) => {
    store.chartPreferences = preferences || {
      interval: '1D',
      type: 1,
      indicators: [],
      priceSide: DEFAULT_TRADINGVIEW_PRICE_SIDE,
      showMarks: true,
    };
    storage.setItem(StorageKey.CHARTS, store.chartPreferences);
  }),

  setGreenIdInfo: action((greenIdRef: string, greenIdStatus: GreenIdStatusEnum) => {
    if (store.userProfile?.verification) {
      store.userProfile.verification.greenid.id = greenIdRef;
      store.userProfile.verification.greenid.status = greenIdStatus;
    }
  }),

  getName: () => {
    if (store.userProfile) {
      if (
        store.userProfile.entityDetails?.entityType !== EntityTypeEnum.PERSONAL &&
        store.userProfile.entityDetails?.entityName
      ) {
        // get name for an entity
        return store.userProfile.entityDetails?.entityName;
      }

      if (store.userProfile.name.first || store.userProfile.name.last) {
        // get name for a regular user
        return `${store.userProfile.name.first} ${store.userProfile.name.last}`;
      }
    }

    return '';
  },

  getNameDetails: () => store.userProfile?.name ?? null,

  getMaskedPhoneNumber: () => store.userProfile?.phone || '',

  getMaskedEmail: () => store.userProfile?.email || '',

  getMaskedAddress: () => store.userProfile?.address ?? null,

  setEntityUuid: action((entityUuid?: string) => {
    store.entityUuid = entityUuid;
  }),

  setEntityAccounts: action((entityAccounts: EntityAccount[]) => {
    store.entityAccounts = entityAccounts;
  }),

  resetEntityAccounts: action(() => {
    store.entityAccounts = [];
  }),

  setEntityMembers: action((entityMembers: EntityMember[]) => {
    store.entityMembers = entityMembers;
  }),

  resetEntityMembers: action(() => {
    store.entityMembers = [];
  }),

  setEntityColorIndex: action((entityColorIndex: number) => {
    store.entityColorIndex = entityColorIndex;
  }),

  resetEntityColorIndex: action(() => {
    store.entityColorIndex = undefined;
  }),

  setAffiliate: action((affiliate: Affiliate) => {
    store.affiliate = affiliate;
  }),

  setUserStatistics: action((userStatistics?: UserStatistics) => {
    store.userStatistics = userStatistics;
  }),

  toggleAssetToDust: action((assetId: number) => {
    const index = store.assetsToDust.indexOf(assetId);
    if (index === -1) {
      store.assetsToDust.push(assetId);
    } else {
      store.assetsToDust.splice(index, 1);
    }
  }),

  setAssetsToDust: action((assetIds: number[]) => {
    store.assetsToDust = assetIds;
  }),

  resetAssetsToDust: action(() => {
    store.assetsToDust = [];
  }),

  setBaseAsset: action((baseAsset?: Asset) => {
    store.baseAsset = baseAsset || assetService.getAsset(store.userBaseCurrency || store.userProfile?.currency.id);
  }),

  confirmPassword: action(async (password: string) => {
    try {
      if (!store.userProfile) return false;

      const response = await AuthenticationService.ConfirmPassword(store.userProfile?.email, password);

      return response !== undefined;
    } catch (e) {
      // TODO: error
      const error = e as SwyftxError;

      if (isReCaptchaError(error)) {
        store.setRefreshRecaptcha(true);
        store.setRecaptchaToken('');
      }

      return false;
    }
  }),

  isRestricted: (): boolean =>
    [
      AccountStatusEnum.BRONZE_RESTRICTED,
      AccountStatusEnum.SILVER_RESTRICTED,
      AccountStatusEnum.GOLD_RESTRICTED,
      AccountStatusEnum.GOLD_NZ_RESTRICTED,
      AccountStatusEnum.DIAMOND_RESTRICTED,
    ].includes(store.getAccountStatus()),
});

/** Side effect for shortcut to safe user base currency */
autorun(() => {
  const userBaseCurrency = store?.userProfile?.currency.id ?? FiatIdEnum.USD;
  const userCountryCurrency = store?.userProfile?.countryCurrency?.id ?? FiatIdEnum.USD;
  const userCountryCurrencyCode = store?.userProfile?.countryCurrency?.code ?? FiatCodeEnum.USD;
  runInAction(() => {
    store.userBaseCurrency = userBaseCurrency;
    store.userCountryCurrency = userCountryCurrency;
    store.userCountryCurrencyCode = userCountryCurrencyCode as FiatCodeEnum;
  });
});

/** Side effect for save scopes into an array */
autorun(() => {
  const scopes = store?.userAuth?.scope;
  runInAction(() => {
    store.scopeArray = scopes ? scopes.split(' ') : [];
  });
});

export { store as useUserStore };
