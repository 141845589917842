import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { Edit } from '@swyftx/aviary/icons/outlined';
import { IconButton, Stack, Typography } from '@swyftx/react-web-design-system';

import { CountryDropdown } from '@global-components/Dropdown';
import { AddressInput } from '@global-components/Input/AddressInput/AddressInput';
import { AddressInputLabel } from '@global-components/Input/AddressInput/AddressInputLabel';

import { CountriesEnum } from '@shared/enums';
import { uuid } from '@shared/utils';

import { addressToString } from '@routes/Onboarding/utils/addressToString';

import { useOnboardingSelector, useOnboardingService } from '@Onboarding/Onboarding.context';
import { VerificationData } from '@Onboarding/types/Onboarding.types';

import { Controller, useForm } from 'react-hook-form';

import { OnboardingStepContainer } from './OnboardingStepContainer';

enum DualCitizenship {
  TRUE = 'yes',
  FALSE = 'no',
}

type FormInputs = Pick<VerificationData, 'country' | 'address' | 'citizenshipCountry' | 'dualCitizenshipCountry'> & {
  dualCitizenship: DualCitizenship;
};

const AddressAndCitizenshipStep: React.FC = () => {
  const { t } = useTranslation('onboarding');
  const sessionUuid = useRef(uuid());
  const onboardingService = useOnboardingService();
  const verificationData = useOnboardingSelector((state) => state.context.verificationData);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    watch,
  } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      country: verificationData.country ?? CountriesEnum.AU,
      address: verificationData.address,
      citizenshipCountry: verificationData.citizenshipCountry,
      dualCitizenship: verificationData.dualCitizenshipCountry ? DualCitizenship.TRUE : DualCitizenship.FALSE,
      dualCitizenshipCountry: verificationData.dualCitizenshipCountry,
    },
  });

  const address = watch('address');
  const addressString = addressToString(address);

  const addressInput = useRef<HTMLInputElement>();

  // Use this to control whether to show the address Autocomplete input or view only address label
  const [addressEditMode, setAddressEditMode] = useState(!address);

  const onSubmit = (data: FormInputs) => {
    onboardingService.send({ type: 'NEXT', data: isDirty ? data : undefined });
  };

  const selectedCountry = watch('country');
  const citizenshipCountry = watch('citizenshipCountry');
  const dualCitizenship = watch('dualCitizenship');
  const dualCitizenshipCountry = watch('dualCitizenshipCountry');
  const isDualCitizen = dualCitizenship === DualCitizenship.TRUE;

  return (
    <OnboardingStepContainer onSubmit={handleSubmit(onSubmit)} submitDisabled={!isValid}>
      <Typography color='text.secondary' marginBottom={4}>
        {t('steps.setupProfile.address.description')}
      </Typography>

      <Controller
        name='dualCitizenship'
        control={control}
        render={({ field }) => (
          <FormControl>
            <FormLabel
              id='onboarding.dualCitizenship'
              sx={{
                fontWeight: 600,
                fontSize: 14,
                color: 'text.primary',
                '&.Mui-focused': {
                  color: 'text.primary',
                },
              }}
            >
              {t('fields.haveDualCitizenship.label')}
            </FormLabel>

            <RadioGroup
              aria-labelledby='onboarding.dualCitizenship'
              row
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              sx={{ marginBottom: 2 }}
            >
              <FormControlLabel
                value='yes'
                control={<Radio />}
                sx={{ '.MuiTypography-root': { fontSize: 14 } }}
                label={t('labels.yes')}
                labelPlacement='end'
              />
              <FormControlLabel
                value='no'
                control={<Radio />}
                sx={{ '.MuiTypography-root': { fontSize: 14 } }}
                label={t('labels.no')}
                labelPlacement='end'
              />
            </RadioGroup>
          </FormControl>
        )}
      />
      {dualCitizenship && (
        <Stack direction='column'>
          <Controller
            name='country'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CountryDropdown
                id='onboarding.country'
                label={t('fields.country.label')}
                onChange={field.onChange}
                value={field.value}
                required
                onlySuggested
              />
            )}
          />
          <Controller
            name='citizenshipCountry'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CountryDropdown
                id='onboarding.citizenshipCountry'
                label={
                  dualCitizenship === 'yes'
                    ? t('fields.primaryCountryOfCitizenship.label')
                    : t('fields.countryOfCitizenship.label')
                }
                placeholder={t('fields.countryOfCitizenship.placeholder')}
                disabledOptions={dualCitizenshipCountry ? [dualCitizenshipCountry] : []}
                onChange={field.onChange}
                value={field.value as CountriesEnum}
                error={!!errors.citizenshipCountry}
                required
              />
            )}
          />

          {isDualCitizen && (
            <Controller
              name='dualCitizenshipCountry'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CountryDropdown
                  id='onboarding.citizenshipCountry'
                  label={t('fields.secondaryCountryOfCitizenship.label')}
                  placeholder={t('fields.secondaryCountryOfCitizenship.placeholder')}
                  onChange={field.onChange}
                  disabledOptions={citizenshipCountry ? [citizenshipCountry] : []}
                  value={field.value as CountriesEnum}
                  error={!!errors.citizenshipCountry}
                  required
                />
              )}
            />
          )}

          <Controller
            name='address'
            control={control}
            rules={{ required: t('fields.address.required') }}
            render={({ field }) =>
              addressEditMode ? (
                <AddressInput
                  inputRef={addressInput}
                  required
                  sessionUuid={sessionUuid.current}
                  country={selectedCountry}
                  onSelectAddress={field.onChange as any}
                  error={!!errors.address}
                  helperText={errors.address?.message}
                  inlineManualInput
                />
              ) : (
                <Stack direction='row' alignItems='center' justifyContent='space-between' marginBottom={2}>
                  <Stack>
                    <AddressInputLabel required />
                    <Typography>{addressString}</Typography>
                  </Stack>
                  <IconButton
                    onClick={() => {
                      setAddressEditMode(true);
                      setTimeout(() => {
                        addressInput.current?.focus();
                      }, 300);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Stack>
              )
            }
          />
        </Stack>
      )}
    </OnboardingStepContainer>
  );
};

export { AddressAndCitizenshipStep };
