import React, { useContext, useEffect, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { FullPageCard } from '@global-components/FullPageCard';

import { ReverificationStatusEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';

import { ReverificationContext } from '@Reverification/Reverification.context';
import { observer } from 'mobx-react-lite';
import { NavigationURLs } from 'src/lib/navigation/types';

import { DateOfBirthStep, GreenIdStep, ReviewDetailsStep, UpdateAddressStep, UpdateNameStep } from './components';
import { getAvoEventStepId, formatAvoDate, getCountry } from './utils';

const Reverification: React.FC = observer(() => {
  const avo = useAvo();
  const { pathname } = useLocation();
  const { currentStep, setCurrentStep } = useContext(ReverificationContext);

  const { isAU, isNZ, userProfile, reverificationRequiredBy } = UserStore.useUserStore;
  const reverification = userProfile?.accountStatus?.reverification ?? null;

  const ActiveStepComponent = useMemo(() => {
    switch (currentStep) {
      case 'DATE_OF_BIRTH':
        return DateOfBirthStep;
      case 'REVIEW_DETAILS':
        return ReviewDetailsStep;
      case 'UPDATE_NAME':
        return UpdateNameStep;
      case 'UPDATE_ADDRESS':
        return UpdateAddressStep;
      case 'GREEN_ID':
        return GreenIdStep;
    }
  }, [currentStep]);

  useEffect(() => {
    if (reverification?.status === ReverificationStatusEnum.IN_PROGRESS) {
      setCurrentStep('GREEN_ID');
    }
  }, []);

  useEffect(() => {
    if (currentStep !== 'GREEN_ID') {
      avo.confirmDetailsStepStarted({
        screen: pathname,
        stepId: getAvoEventStepId(currentStep),
        requiredBy: formatAvoDate(reverificationRequiredBy()),
        country: getCountry({ isAU, isNZ }),
      });
    }
  }, [avo, currentStep]);

  if (!reverification) {
    return <Navigate to={NavigationURLs.Dashboard} />;
  }

  return <FullPageCard id='reverification'>{ActiveStepComponent && <ActiveStepComponent />}</FullPageCard>;
});

Reverification.displayName = 'Confirm your details';

export default Reverification;
