import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Stack } from '@swyftx/react-web-design-system';

import { SourceOfWealthRequiredNotification } from '@global-components/SourceOfWealthNotification/SourceOfWealthRequiredNotification';
import { WithdrawSendDetails } from '@global-components/WithdrawSendDetails';
import { useWithdrawFunds } from '@global-components/WithdrawSendDetails/WithdrawSendDetails.utils';
import { InformationMessageBox } from '@global-components/message-boxes/InformationMessageBox';

import { api } from '@shared/api';
import { Asset, AssetType } from '@shared/api/@types/markets';
import { WithdrawalAddress, WithdrawalReasonEnum } from '@shared/services';
import { UIStore, UserStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useWithdrawalPermission } from '@hooks/Withdraw/useWithdrawalPermission';

import { observer } from 'mobx-react-lite';
import { useIntercom } from 'react-use-intercom';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { TransferWithdrawFees } from './components/TransferWithdrawSendFees';
import { TransferModalStep } from '../../../TransferModal.types';

type Props = {
  initialAsset?: Asset;
  withdrawAddress?: WithdrawalAddress;
  withdrawReason?: WithdrawalReasonEnum;
  withdrawAmount: string;
  setWithdrawToken: React.Dispatch<React.SetStateAction<string>>;
  setWithdrawAmount: React.Dispatch<React.SetStateAction<string>>;
  setWithdrawAddress: React.Dispatch<React.SetStateAction<WithdrawalAddress | undefined>>;
  setWithdrawReason: React.Dispatch<React.SetStateAction<WithdrawalReasonEnum | undefined>>;
  setOnAction: (action: () => void) => void;
  setDisabled: (disabled: boolean) => void;
  setHideContinue?: (hide: boolean) => void;
  onClose: () => void;
  onNextStep: (step: number, pushToStack?: boolean | undefined) => void;
  onReceive: () => void;
};

export const TransferWithdrawSend: React.FC<Props> = observer(
  ({
    initialAsset,
    withdrawAddress,
    withdrawReason,
    withdrawAmount,
    setWithdrawToken,
    setWithdrawAddress,
    setWithdrawAmount,
    setWithdrawReason,
    setOnAction,
    setDisabled,
    onClose,
    onNextStep,
    onReceive,
    setHideContinue,
  }) => {
    const { t } = useTranslation('modals', { keyPrefix: 'transferModal.withdraw' });
    const { addMessageBox } = UIStore.useUIStore;

    const { isKyc2Required } = UserStore.useUserStore;
    const { isFeatureEnabled } = useIsFeatureEnabled();
    const sourceOfWealthRequired = isFeatureEnabled(AppFeature.SourceOfWealth) && isKyc2Required();

    const [error, setError] = useState('');

    const [selectedAsset, setSelectedAsset] = useState<Asset | null>(initialAsset || null);

    const [updatedNameOnAccount, setUpdatedNameOnAccount] = useState<string | undefined>('');

    const { withdrawFunds } = useWithdrawFunds(selectedAsset, withdrawAmount, withdrawReason, withdrawAddress);

    const baseAsset = useBaseAsset();
    const countryAsset = useCountryAsset();

    const isFiat = selectedAsset?.assetType === AssetType.Fiat;
    const { userProfile } = UserStore.useUserStore;

    const userAccountWithdrawalsDisabled = useCallback(
      () => userProfile?.accountStatus?.withdrawals === 0,
      [userProfile],
    );

    const assetLevelWithdrawalsDisabled = !selectedAsset?.withdraw_enabled;
    const assetOrUserWithdrawalsDisabled =
      assetLevelWithdrawalsDisabled || (isFiat && userProfile?.countryCurrency?.id !== selectedAsset?.id);
    const withdrawalBlockedStatus = useWithdrawalPermission(selectedAsset?.code);
    const isUserCountryCurrency = isFiat && UserStore.useUserStore.userCountryCurrency === selectedAsset.id;

    const hasActiveNetworks = useMemo(() => {
      if (!selectedAsset || selectedAsset.assetType === AssetType.Fiat) return true;

      return !selectedAsset.networks.every((network) => network.withdrawDisableForce || network.withdrawDisabled);
    }, [selectedAsset]);

    const { show } = useIntercom();

    /**
     * @fiatButNotTheirResidentialFiat - If the selected asset is not the user's country currency,
     * !isUserCountryCurrency - returns true if the selected asset is FIAT and not the user's residential currency.
     * Notification Priority: 1
     */
    const fiatButNotTheirResidentialFiat = selectedAsset && isFiat && !isUserCountryCurrency;

    /**
     * @assetWithdrawalsBlocked - If the selected asset has withdrawals disabled at the asset level.
     * assetLevelWithdrawalsDisabled - returns true if the selected asset has withdrawals disabled on an asset level.
     * !fiatButNotTheirResidentialFiat - returns true if selected asset is their residentual fiat or crypto.
     * Notification Priority: 2
     */
    const assetWithdrawalsBlocked = selectedAsset && assetLevelWithdrawalsDisabled && !fiatButNotTheirResidentialFiat;

    /**
     * @userAccountBlocked - If the current user has withdrawals disabled.
     * !fiatButNotTheirResidentialFiat - returns true if selected asset is their residentual fiat or crypto.
     * !assetLevelWithdrawalsDisabled - returns true if the selected asset has withdrawals enabled.
     * userAccountWithdrawalsDisabled() - returns true if the user has withdrawals disable at an account level.
     * Notification Priority: 3
     */
    const userAccountBlocked =
      selectedAsset &&
      !assetLevelWithdrawalsDisabled &&
      userAccountWithdrawalsDisabled() &&
      !fiatButNotTheirResidentialFiat;

    /**
     * @noActiveNetworks - If the selected crypto asset has no active networks.
     * !assetOrUserWithdrawalsDisabled - returns true if selected asset has withdrawals enabled and user has withdrawals enabled.
     * !userAccountWithdrawalsDisabled() - returns true if the user has withdrawals enabled at an account level.
     * !hasActiveNetworks - returns true if the selected asset has no active networks.
     * Notification Priority: 4
     */
    const noActiveNetworks =
      selectedAsset && !assetOrUserWithdrawalsDisabled && !userAccountWithdrawalsDisabled() && !hasActiveNetworks;

    useEffect(() => {
      if (initialAsset) setSelectedAsset(initialAsset);
    }, [initialAsset]);

    const requiresUpdatedNameOnAccount = useMemo(
      () => selectedAsset?.code === 'AUD' && withdrawAddress && !withdrawAddress?.address_details.nameOnAccount,
      [selectedAsset?.code, withdrawAddress],
    );

    const isDirty = useMemo(() => {
      if (!selectedAsset) return false;
      if (!withdrawAmount) return false;
      if (!withdrawReason) return false;
      if (!withdrawAddress) return false;
      if (requiresUpdatedNameOnAccount && (!updatedNameOnAccount || updatedNameOnAccount.length < 2)) return false;

      return true;
    }, [
      requiresUpdatedNameOnAccount,
      selectedAsset,
      updatedNameOnAccount,
      withdrawAddress,
      withdrawAmount,
      withdrawReason,
    ]);

    const updateNameOnAccount = useCallback(
      (code: string, addressId: number, nameOnAccount: string) =>
        api.endpoints.updateWithdrawAddress({
          params: {
            code,
          },
          data: {
            address: {
              id: addressId,
              addressDetails: {
                nameOnAccount,
              },
            },
          },
        }),
      [],
    );

    useEffect(() => {
      if (setHideContinue) {
        if (selectedAsset) {
          setHideContinue(false);
        } else {
          setHideContinue(true);
        }
      }
    }, [initialAsset, selectedAsset, setHideContinue]);

    useEffect(() => {
      if (error.length < 1 && isDirty) {
        setOnAction(() => async () => {
          try {
            setDisabled(true);

            // If we are transferring AUD but there is no name on the account, update the details first
            if (
              selectedAsset?.code === 'AUD' &&
              withdrawAddress &&
              !withdrawAddress?.address_details.nameOnAccount &&
              updatedNameOnAccount
            ) {
              await updateNameOnAccount(selectedAsset.code, withdrawAddress.id, updatedNameOnAccount);
            }

            const response = await withdrawFunds();
            if (response.smsVerificationRequired && response.token) {
              setWithdrawToken(response.token);
              onNextStep(TransferModalStep.PhoneVerificationWithdraw, false);
              addMessageBox({
                anchorOrigin: {
                  horizontal: 'center',
                  vertical: 'bottom',
                },
                content: <InformationMessageBox title={t('smsSent')} />,
              });
            } else {
              onClose();
            }
          } catch (ex) {
            // Todo
          } finally {
            setDisabled(false);
          }
        });
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }, [
      error,
      setOnAction,
      setDisabled,
      withdrawFunds,
      onClose,
      isDirty,
      updatedNameOnAccount,
      selectedAsset,
      withdrawAddress,
      updateNameOnAccount,
      setWithdrawToken,
      onNextStep,
      addMessageBox,
      t,
    ]);

    if (!baseAsset || !countryAsset) return null;

    if (userAccountWithdrawalsDisabled() && !assetOrUserWithdrawalsDisabled) {
      return (
        <Stack>
          <FlexLayout direction='column' spacing={24}>
            <Notification severity='destructive' title={t('accountWithdrawalsDisabled.title')}>
              <FlexLayout direction='column' spacing={16}>
                <Body color='secondary'>{t('accountWithdrawalsDisabled.message')}</Body>
                <div>
                  <Button
                    color='destructive'
                    variant='filled'
                    className='intercom-trigger-bot-withdrawals-blocked'
                    onClick={show}
                  >
                    Contact support
                  </Button>
                </div>
              </FlexLayout>
            </Notification>
          </FlexLayout>
        </Stack>
      );
    }

    return (
      <Stack>
        {selectedAsset && (
          <Stack spacing={2}>
            {(sourceOfWealthRequired || isFiat) && (
              <FlexLayout direction='column' spacing={12}>
                {sourceOfWealthRequired && <SourceOfWealthRequiredNotification action={onClose} />}
                {isFiat && !assetOrUserWithdrawalsDisabled && (
                  <Notification severity='info' title={t('info.title')}>
                    {t('info.message')}
                  </Notification>
                )}
              </FlexLayout>
            )}

            {hasActiveNetworks && !assetOrUserWithdrawalsDisabled && (
              <>
                <WithdrawSendDetails
                  withdrawDisabled={assetOrUserWithdrawalsDisabled}
                  loading={withdrawalBlockedStatus.loading}
                  setWithdrawAddress={setWithdrawAddress}
                  setWithdrawAmount={setWithdrawAmount}
                  setWithdrawReason={setWithdrawReason}
                  withdrawAddress={withdrawAddress}
                  withdrawAmount={withdrawAmount}
                  withdrawReason={withdrawReason}
                  onClose={onClose}
                  onReceive={onReceive}
                  countryAsset={countryAsset}
                  baseAsset={baseAsset}
                  asset={selectedAsset}
                  setError={setError}
                  updatedNameOnAccount={updatedNameOnAccount}
                  setUpdatedNameOnAccount={setUpdatedNameOnAccount}
                  error={error}
                />
                {!assetOrUserWithdrawalsDisabled && !withdrawalBlockedStatus.loading && (
                  <TransferWithdrawFees
                    selectedAsset={selectedAsset}
                    withdrawAmount={withdrawAmount}
                    withdrawAddress={withdrawAddress}
                    onNextStep={onNextStep}
                  />
                )}
              </>
            )}
          </Stack>
        )}

        {fiatButNotTheirResidentialFiat && (
          <Notification
            severity='destructive'
            title={t('withdrawalNotSupportedTitle', { assetName: selectedAsset?.name })}
          >
            {t('withdrawalDisabledWrongFIAT')}
          </Notification>
        )}

        {assetWithdrawalsBlocked && (
          <Notification
            severity='destructive'
            title={t('withdrawalNotSupportedTitle', { assetName: selectedAsset?.name })}
          >
            {t('withdrawalDisabled', { assetName: selectedAsset?.name })}
          </Notification>
        )}

        {userAccountBlocked && (
          <Notification
            severity='destructive'
            title={
              selectedAsset
                ? t('userAccountBlocked.selectedAsset', { selectedAsset: selectedAsset?.name })
                : t('userAccountBlocked.notSelectedAsset')
            }
            actions={
              <Button
                color='destructive'
                variant='filled'
                className='intercom-trigger-bot-withdrawals-blocked'
                onClick={show}
              >
                Contact support
              </Button>
            }
          >
            {withdrawalBlockedStatus.message}
          </Notification>
        )}

        {noActiveNetworks && (
          <Notification
            severity='destructive'
            title={t('withdrawalNotSupportedTitle', { assetName: selectedAsset?.name })}
          >
            {t('withdrawalsSuspended', { assetName: selectedAsset?.name })}
          </Notification>
        )}
      </Stack>
    );
  },
);
