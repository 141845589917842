import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Body } from '@swyftx/aviary/atoms/Typography';

import { Asset, AssetType } from '@shared/api';
import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';

type Props = {
  asset: Asset;
};

const LimitedSendNotification: React.FC<Props> = ({ asset }) => {
  const { t } = useTranslation('assets', { keyPrefix: 'singleAsset' });

  const withdrawRestricted = useMemo(() => !assetService.canWithdrawAsset(asset), [asset]);
  const depositRestricted = useMemo(() => !assetService.canDepositAsset(asset), [asset]);
  const { userCountryCurrency } = UserStore.useUserStore;

  const content = useMemo(() => {
    if (asset.assetType === AssetType.Fiat && asset.id !== userCountryCurrency)
      return t('limitedWithdrawal.withdrawalDisabled', { asset: asset.name });

    if (withdrawRestricted && depositRestricted)
      return t('limitedWithdrawal.withdrawalDisabled', { asset: asset.name });

    if (withdrawRestricted && !depositRestricted)
      return t('limitedWithdrawal.withdrawRestricted', { asset: asset.name });

    if (!withdrawRestricted && depositRestricted)
      return t('limitedWithdrawal.depositRestricted', { asset: asset.name });

    return null;
  }, [asset, withdrawRestricted, depositRestricted, userCountryCurrency, t]);

  if (!withdrawRestricted && !depositRestricted) return null;

  return (
    <Body size='small' className='inline'>
      {content}
    </Body>
  );
};

export default LimitedSendNotification;
