import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { CountdownDelay } from '@global-components/CountdownDelay';

import { isSwyftxError } from '@shared/error-handler';
import { UIStore, UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';

import { observer } from 'mobx-react-lite';
import { useIntercom } from 'react-use-intercom';

import { getAvoEventStepId, formatAvoDate, getCountry } from '../../../utils';

type Props = {
  verificationToken?: string;
  processing?: boolean;
  onSubmit: (token?: string, code?: string) => void;
  onClose: () => void;
};

const INITIAL_CODE_TIMEOUT = 60;
const MAX_ATTEMPTS = 3;

export const PhoneVerificationModal: React.FC<Props> = observer(
  ({ verificationToken, processing, onSubmit, onClose }) => {
    const { addToastMessage } = UIStore.useUIStore;
    const { isAU, isNZ, getMaskedPhoneNumber, reverificationRequiredBy } = UserStore.useUserStore;
    const [code, setCode] = useState<string>('');
    const [attempts, setAttempts] = useState(0);

    const avo = useAvo();
    const { pathname } = useLocation();

    const { t } = useTranslation('reverifications');

    const { show } = useIntercom();

    const rateLimit = useMemo(() => attempts >= MAX_ATTEMPTS, [attempts]);

    const onResendCode = useCallback(async () => {
      setCode('');
      setAttempts(0);
      await onSubmit();
    }, [onSubmit, setCode, setAttempts]);

    const onSubmitCode = useCallback(async () => {
      try {
        await onSubmit(verificationToken, code);
        avo.confirmDetailsStepCompleted({
          screen: pathname,
          stepId: getAvoEventStepId('OTP_CODE'),
          requiredBy: formatAvoDate(reverificationRequiredBy()),
          country: getCountry({ isAU, isNZ }),
        });
        onClose();
      } catch (error) {
        if (isSwyftxError(error)) {
          if (error.errorMessage === 'Invalid OTP code') {
            setAttempts((prev) => prev + 1);
          }
          addToastMessage({ severity: 'error', message: error.errorMessage });
        } else {
          addToastMessage({ severity: 'error', message: t('misc.genericError') });
        }
      }
    }, [verificationToken, onSubmit, onClose, addToastMessage, code, setAttempts]);

    useEffect(() => {
      if (verificationToken) {
        avo.confirmDetailsStepStarted({
          screen: pathname,
          stepId: getAvoEventStepId('OTP_CODE'),
          requiredBy: formatAvoDate(reverificationRequiredBy()),
          country: getCountry({ isAU, isNZ }),
        });
      }
    }, [avo, verificationToken]);

    return (
      <Modal title={t('phoneVerification.title')} open={!!verificationToken} onClose={onClose}>
        <FlexLayout direction='column' spacing={48} className='px-24 pb-24'>
          <FlexLayout direction='column' spacing={16}>
            <Body color='secondary' className='lg:max-w-[552px]'>
              <Trans
                t={t}
                i18nKey='phoneVerification.body'
                values={{ phone: getMaskedPhoneNumber() }}
                components={[
                  <strong key='phone' />,
                  <a
                    key='support'
                    className='text-decoration-underline-solid text-color-link-main'
                    href='#'
                    onClick={(e) => {
                      e.preventDefault();
                      show();
                    }}
                  />,
                ]}
              />
            </Body>
            <FlexLayout direction='column' spacing={8}>
              <Input
                type='number'
                min='0'
                max='999999'
                className='fs-mask'
                onChange={(e) => setCode(e.target.value)}
                value={code}
                error={rateLimit}
                disabled={rateLimit}
                placeholder={t('phoneVerification.fields.code.placeholder')}
              />
              {rateLimit && (
                <Body color='error' size='small'>
                  {t('phoneVerification.misc.maxAttemptsReached')}
                </Body>
              )}
              <CountdownDelay
                id='mobile-verification-resend-countdown'
                delay={INITIAL_CODE_TIMEOUT}
                delayText={t('phoneVerification.misc.resendCodeIn')}
                restartText={t('phoneVerification.misc.resendCode')}
                onTimerRestart={onResendCode}
              />
            </FlexLayout>
          </FlexLayout>
          <FlexLayout direction='row' justifyContent='end'>
            <Button size='lg' onClick={onSubmitCode} disabled={!code.length} loading={processing}>
              {t('phoneVerification.actions.submit')}
            </Button>
          </FlexLayout>
        </FlexLayout>
      </Modal>
    );
  },
);
