import React, { createContext, useState } from 'react';

import { AddressBrokenUp } from '@shared/api';

import { DateTime } from 'luxon';

export type ReverificationStep = 'DATE_OF_BIRTH' | 'REVIEW_DETAILS' | 'UPDATE_NAME' | 'UPDATE_ADDRESS' | 'GREEN_ID';

export type ReverificationContextType = {
  currentStep: ReverificationStep;
  editingDateOfBirth: boolean;
  dateOfBirth?: DateTime;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  address?: AddressBrokenUp;
  setCurrentStep: React.Dispatch<React.SetStateAction<ReverificationStep>>;
  setEditingDateOfBirth: React.Dispatch<React.SetStateAction<boolean>>;
  setDateOfBirth: React.Dispatch<React.SetStateAction<DateTime | undefined>>;
  setFirstName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setMiddleName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setLastName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setAddress: React.Dispatch<React.SetStateAction<AddressBrokenUp | undefined>>;
};

export const ReverificationContext = createContext<ReverificationContextType>({
  currentStep: 'DATE_OF_BIRTH',
  editingDateOfBirth: false,
  dateOfBirth: undefined,
  firstName: undefined,
  middleName: undefined,
  lastName: undefined,
  address: undefined,
  setCurrentStep: () => {},
  setEditingDateOfBirth: () => {},
  setDateOfBirth: () => {},
  setFirstName: () => {},
  setMiddleName: () => {},
  setLastName: () => {},
  setAddress: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const ReverificationProvider: React.FC<Props> = ({ children }) => {
  const [currentStep, setCurrentStep] = useState<ReverificationStep>('DATE_OF_BIRTH');
  const [editingDateOfBirth, setEditingDateOfBirth] = useState<boolean>(false);
  const [dateOfBirth, setDateOfBirth] = useState<DateTime | undefined>(undefined);
  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [middleName, setMiddleName] = useState<string | undefined>(undefined);
  const [lastName, setLastName] = useState<string | undefined>(undefined);
  const [address, setAddress] = useState<AddressBrokenUp | undefined>(undefined);

  return (
    <ReverificationContext.Provider
      value={{
        currentStep,
        editingDateOfBirth,
        dateOfBirth,
        firstName,
        middleName,
        lastName,
        address,
        setCurrentStep,
        setEditingDateOfBirth,
        setDateOfBirth,
        setFirstName,
        setMiddleName,
        setLastName,
        setAddress,
      }}
    >
      {children}
    </ReverificationContext.Provider>
  );
};
