import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { Label } from '@swyftx/aviary/atoms/Label';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';

import { api, AddressBrokenUp, ReverificationParams } from '@shared/api';
import { ReverificationStatusEnum } from '@shared/enums';
import { isSwyftxError } from '@shared/error-handler';
import { UIStore, UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';
import UserService from '@services/UserService';

import { ReverificationContext } from '@Reverification/Reverification.context';
import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { BaseReverificationStep } from './components/BaseReverificationStep';
import { DetailPreview } from './components/DetailPreview';
import { PhoneVerificationModal } from './components/PhoneVerificationModal';
import { getAvoEventStepId, formatAvoDate, getCountry } from '../../utils';

export const ReviewDetailsStep: React.FC = observer(() => {
  const avo = useAvo();
  const { pathname } = useLocation();
  const { dateOfBirth, firstName, middleName, lastName, address, setCurrentStep, setEditingDateOfBirth } =
    useContext(ReverificationContext);
  const { addToastMessage } = UIStore.useUIStore;
  const { isAU, isNZ, getNameDetails, getMaskedAddress, reverificationRequiredBy } = UserStore.useUserStore;
  const [detailsConfirmed, setDetailsConfirmed] = useState<boolean>(false);
  const [verificationToken, setVerificationToken] = useState<string | undefined>(undefined);
  const [processing, setProcessing] = useState<boolean>(false);

  const { t } = useTranslation('reverifications');

  const { navigate } = useNavigateRoute();

  const dobPreview = useMemo(() => {
    if (!dateOfBirth?.isValid) {
      return '---';
    }
    return dateOfBirth.toFormat('dd MMM yyyy').toUpperCase();
  }, [dateOfBirth]);

  const namePreview = useMemo(() => {
    let data: { firstName?: string; middleName?: string | null; lastName?: string } | undefined;
    if (firstName || middleName || lastName) {
      data = { firstName, middleName, lastName };
    } else {
      const details = getNameDetails();
      if (details) {
        data = {
          firstName: details.first,
          middleName: details.middle,
          lastName: details.last,
        };
      }
    }
    if (!data) {
      return '---';
    }
    return [data.firstName, data.middleName, data.lastName].filter((v) => !!v).join(' ');
  }, [firstName, middleName, lastName, getNameDetails]);

  const addressPreview = useMemo(() => {
    let data: AddressBrokenUp | undefined;
    if (address) {
      data = address;
    } else {
      const masked = getMaskedAddress();
      if (masked) {
        data = {
          flatNumber: masked.unit || '',
          streetNumber: masked.streetNum || '',
          streetName: masked.streetName || '',
          streetType: masked.streetType || '',
          postcode: masked.postcode || '',
          // Check first char of masked country to determine country code
          ...(masked.country.charAt(0) === 'A'
            ? {
                suburb: masked.suburb || '',
                state: masked.state || '',
                country: 'AU',
              }
            : {
                suburb: masked.suburb || '',
                // townCity is recorded in state field in user profile for NZ users
                townCity: masked.state || '',
                country: 'NZ',
              }),
        };
      }
    }
    if (!data) {
      return '---';
    }
    const { flatNumber, streetNumber, streetName, streetType, suburb, postcode, country } = data;
    const cityOrState = country === 'AU' ? data.state : data.townCity;
    const part1 = [flatNumber, streetNumber, streetName, streetType].filter((v) => !!v).join(' ');
    const part2 = suburb;
    const part3 = [cityOrState, postcode].filter((v) => !!v).join(' ');
    return [part1, part2, part3].filter((v) => !!v).join(', ');
  }, [address, getMaskedAddress]);

  const onEditName = () => {
    setCurrentStep('UPDATE_NAME');
  };
  const onEditDob = () => {
    setEditingDateOfBirth(true);
    setCurrentStep('DATE_OF_BIRTH');
  };
  const onEditAddress = () => {
    setCurrentStep('UPDATE_ADDRESS');
  };

  const getReverificationData = (): ReverificationParams => {
    const name =
      firstName && lastName
        ? {
            first: firstName,
            middle: middleName,
            last: lastName,
          }
        : null;

    const dob = dateOfBirth!.toMillis();

    const addressData = address
      ? {
          unit: address.flatNumber,
          strNum: address.streetNumber,
          strName: address.streetName,
          strType: address.streetType,
          postcode: parseInt(address.postcode),
          ...(address.country === 'AU'
            ? ({
                suburb: address.suburb,
                state: address.state,
                country: 'AU',
              } as const)
            : ({
                suburb: address.suburb,
                townCity: address.townCity,
                country: 'NZ',
              } as const)),
        }
      : null;

    return { name, dob, address: addressData };
  };

  const handleReverificationStatus = async (status: ReverificationStatusEnum) => {
    avo.confirmDetailsStepCompleted({
      screen: pathname,
      stepId: getAvoEventStepId('REVIEW_DETAILS'),
      requiredBy: formatAvoDate(reverificationRequiredBy()),
      country: getCountry({ isAU, isNZ }),
    });
    // Refresh user profile to update reverification status & address fields
    await UserService.GetUserProfile();
    // If verification is still in progress, GreenID is required
    // Otherwise, reverification is complete - return to dashboard
    if (status === 'IN_PROGRESS') {
      setCurrentStep('GREEN_ID');
    } else {
      navigate(NavigationURLs.Dashboard);
    }
  };

  const onSubmit = async (token?: string, code?: string) => {
    const data = getReverificationData();
    const authaction = token && code ? `${token} ${code}` : undefined;
    setProcessing(true);
    try {
      const response = await api.endpoints.reverify({
        data,
        headers: authaction ? { authaction } : {},
      });
      if (response.data.token) {
        setVerificationToken(response.data.token);
      } else if (response.data.status) {
        setVerificationToken(undefined);
        addToastMessage({ severity: 'success', message: t('misc.submitSuccess') });
        handleReverificationStatus(response.data.status);
      } else {
        addToastMessage({ severity: 'error', message: t('misc.genericError') });
      }
    } catch (error) {
      if (isSwyftxError(error)) {
        // Rethrow invalid OTP code errors to verification modal handler
        if (error.errorMessage === 'Invalid OTP code') {
          throw error;
        } else {
          addToastMessage({ severity: 'error', message: error.errorMessage });
        }
      } else {
        addToastMessage({ severity: 'error', message: t('misc.genericError') });
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <BaseReverificationStep
        heading={t('steps.reviewDetails.title')}
        description={t('steps.reviewDetails.description')}
      >
        <FlexLayout direction='column' spacing={4}>
          <DetailPreview
            label={t('steps.reviewDetails.details.fullName.label')}
            hint={t('steps.reviewDetails.details.fullName.hint')}
            value={namePreview}
            onEdit={onEditName}
          />
          <Separator />
          <DetailPreview
            label={t('steps.reviewDetails.details.dateOfBirth.label')}
            value={dobPreview}
            onEdit={onEditDob}
          />
          <Separator />
          <DetailPreview
            label={t('steps.reviewDetails.details.residentialAddress.label')}
            value={addressPreview}
            onEdit={onEditAddress}
          />
        </FlexLayout>
        <FlexLayout direction='column' spacing={16}>
          <FlexLayout direction='row' spacing={12}>
            <Checkbox
              id='detailsUpToDate'
              checked={detailsConfirmed}
              onCheckedChange={(checked) => setDetailsConfirmed(!!checked)}
            />
            <Label htmlFor='detailsUpToDate' className='line-height-20 text-14 font-normal'>
              {t('fields.detailsUpToDate.label')}
            </Label>
          </FlexLayout>
          <Button size='lg' onClick={() => onSubmit()} disabled={!detailsConfirmed} loading={processing}>
            {t('actions.submit')}
          </Button>
        </FlexLayout>
      </BaseReverificationStep>
      <PhoneVerificationModal
        verificationToken={verificationToken}
        processing={processing}
        onSubmit={onSubmit}
        onClose={() => setVerificationToken(undefined)}
      />
    </>
  );
});
