import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Loading } from '@swyftx/aviary/icons/outlined';

import { GreenIDFrame } from '@global-components/GreenIDFrame';

import { api } from '@shared/api';
import { GreenIdStatusEnum } from '@shared/enums';
import { SwyftxError } from '@shared/error-handler';
import { UIStore, UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { BaseReverificationStep } from './components/BaseReverificationStep';
import { formatAvoDate, getCountry } from '../../utils';

export const GreenIdStep: React.FC = observer(() => {
  const avo = useAvo();
  const { pathname } = useLocation();
  const { isAU, isNZ, reverificationRequiredBy } = UserStore.useUserStore;

  const { addToastMessage } = UIStore.useUIStore;
  const [loading, setLoading] = useState<boolean>(false);
  const [greenIdRef, setGreenIdRef] = useState<string | undefined>(undefined);

  const { t } = useTranslation('reverifications');

  const { navigate } = useNavigateRoute();

  const fetchGreenIdRef = async () => {
    setLoading(true);
    try {
      const response = await api.endpoints.reverifyGreenId({});
      if (response.data.greenIdRef) {
        setGreenIdRef(response.data.greenIdRef);
      } else {
        addToastMessage({ severity: 'error', message: t('misc.genericError') });
      }
    } catch (error: any) {
      addToastMessage({ severity: 'error', message: error?.errorMessage ?? t('misc.genericError') });
    } finally {
      setLoading(false);
    }
  };

  const fetchToken = async (ref: string): Promise<string> => {
    const result = await api.endpoints.getNewGreenIDToken({ query: { id: ref, isReverification: true } });
    if (result.data.id !== ref) {
      throw new SwyftxError('Fetch GreenID Token', 'GreenID ref mismatch');
    }
    return result.data.token;
  };

  const storeResult = async (ref: string): Promise<void> => {
    await api.endpoints.setGreenIDInfo({
      data: {
        verification: {
          id: ref,
        },
      },
    });
  };

  const handleSuccess = (greenIdStatus?: GreenIdStatusEnum) => {
    if (greenIdStatus === GreenIdStatusEnum.PENDING_REVIEW) {
      avo.reveriDvsPending({
        screen: pathname,
        requiredBy: formatAvoDate(reverificationRequiredBy()),
        country: getCountry({ isAU, isNZ }),
      });

      addToastMessage({ severity: 'info', message: t('misc.pendingReviewSuccess') });
    } else {
      avo.reveriDvsSuccess({
        screen: pathname,
        requiredBy: formatAvoDate(reverificationRequiredBy()),
        country: getCountry({ isAU, isNZ }),
      });

      addToastMessage({ severity: 'success', message: t('misc.submitSuccess') });
    }
    navigate(NavigationURLs.Dashboard);
  };

  const handleCancel = () => {
    navigate(NavigationURLs.Dashboard);
  };

  const handleError = (error: any) => {
    avo.reveriDvsFail({
      screen: pathname,
      requiredBy: formatAvoDate(reverificationRequiredBy()),
      country: getCountry({ isAU, isNZ }),
    });

    addToastMessage({ severity: 'error', message: error?.errorMessage ?? t('misc.genericError') });
  };

  useEffect(() => {
    avo.reveriDvsStarted({
      screen: pathname,
      requiredBy: formatAvoDate(reverificationRequiredBy()),
      country: getCountry({ isAU, isNZ }),
    });

    fetchGreenIdRef();
  }, []);

  return (
    <BaseReverificationStep heading={t('steps.greenId.title')} description={t('steps.greenId.description')}>
      {loading && (
        <FlexLayout direction='row' justifyContent='center'>
          <Loading className='h-36 w-36 animate-spin' />
        </FlexLayout>
      )}
      {greenIdRef && (
        <GreenIDFrame
          greenIdRef={greenIdRef}
          fetchToken={fetchToken}
          storeResult={storeResult}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          onError={handleError}
        />
      )}
    </BaseReverificationStep>
  );
});
