import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { FormInput } from '@swyftx/aviary/molecules/FormInput/FormInput';

import { AddressBrokenUp } from '@shared/api';
import { SuggestedCountry } from '@shared/enums';

type Props = {
  country: SuggestedCountry;
  onChange: (address: AddressBrokenUp) => void;
  errors?: any;
};

type TrackedValue<T> = {
  value: T;
  dirty?: boolean;
};

export const ManualAddressForm: React.FC<Props> = ({ country, onChange, errors }) => {
  const [flatNumber, setFlatNumber] = useState<TrackedValue<string>>({ value: '' });
  const [streetNumber, setStreetNumber] = useState<TrackedValue<string>>({ value: '' });
  const [streetName, setStreetName] = useState<TrackedValue<string>>({ value: '' });
  const [streetType, setStreetType] = useState<TrackedValue<string>>({ value: '' });
  const [suburb, setSuburb] = useState<TrackedValue<string>>({ value: '' });
  const [townCity, setTownCity] = useState<TrackedValue<string>>({ value: '' });
  const [state, setState] = useState<TrackedValue<string>>({ value: '' });
  const [postcode, setPostcode] = useState<TrackedValue<string>>({ value: '' });
  const { t } = useTranslation('common');

  useEffect(() => {
    onChange({
      country,
      flatNumber: flatNumber.value,
      streetNumber: streetNumber.value,
      streetName: streetName.value,
      streetType: streetType.value,
      suburb: suburb.value,
      townCity: townCity.value,
      state: state.value,
      postcode: postcode.value,
    });
  }, [flatNumber, streetNumber, streetName, streetType, suburb, townCity, state, postcode]);

  return (
    <FlexLayout direction='column' spacing={32}>
      <FormInput
        label={t('manualAddressForm.labels.flatNumber')}
        value={flatNumber.value}
        onChange={(e) => setFlatNumber({ value: e.target.value, dirty: true })}
        error={flatNumber.dirty ? errors?.flatNumber : undefined}
      />
      <FormInput
        label={t('manualAddressForm.labels.streetNumber')}
        required
        value={streetNumber.value}
        onChange={(e) => setStreetNumber({ value: e.target.value, dirty: true })}
        error={streetNumber.dirty ? errors?.streetNumber : undefined}
      />
      <FormInput
        label={t('manualAddressForm.labels.streetName')}
        required
        value={streetName.value}
        onChange={(e) => setStreetName({ value: e.target.value, dirty: true })}
        error={streetName.dirty ? errors?.streetName : undefined}
      />
      <FormInput
        label={t('manualAddressForm.labels.streetType')}
        required
        value={streetType.value}
        onChange={(e) => setStreetType({ value: e.target.value, dirty: true })}
        error={streetType.dirty ? errors?.streetType : undefined}
      />
      <FormInput
        label={t('manualAddressForm.labels.suburb')}
        required
        value={suburb.value}
        onChange={(e) => setSuburb({ value: e.target.value, dirty: true })}
        error={suburb.dirty ? errors?.suburb : undefined}
      />
      {country === 'NZ' && (
        <FormInput
          label={t('manualAddressForm.labels.townCity')}
          required
          value={townCity.value}
          onChange={(e) => setTownCity({ value: e.target.value, dirty: true })}
          error={townCity.dirty ? errors?.townCity : undefined}
        />
      )}
      {country === 'AU' && (
        <FormInput
          label={t('manualAddressForm.labels.state')}
          required
          value={state.value}
          onChange={(e) => setState({ value: e.target.value, dirty: true })}
          error={state.dirty ? errors?.state : undefined}
        />
      )}
      <FormInput
        label={t('manualAddressForm.labels.postcode')}
        required
        value={postcode.value}
        onChange={(e) => setPostcode({ value: e.target.value, dirty: true })}
        error={postcode.dirty ? errors?.postcode : undefined}
      />
    </FlexLayout>
  );
};
