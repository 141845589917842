import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, styled } from '@mui/material';

import { Button, Notification, Stack, Typography } from '@swyftx/react-web-design-system';

import { WarningMessageBox } from '@global-components/message-boxes/WarningMessageBox';

import { api } from '@shared/api';
import env from '@shared/config';
import { GreenIdStatusEnum } from '@shared/enums';
import { UIStore, UserStore } from '@shared/store';

import { useIntercomEvent } from '@hooks/useIntercomEvent/useIntercomEvent';

import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';

// To test Green ID values, use the following document to find the stub values
// https://vixverify.atlassian.net/wiki/spaces/GREEN/pages/8880678/Data+Testing

// Use position absolute (overriding position 'fixed') so GreenID modals are contained within the container
const GreenIDContainer = styled('div')`
  padding: 16px;
  .greenid-modal {
    position: absolute;
  }
  .greenid-modal-backdrop {
    position: absolute;
  }
`;

declare global {
  interface Window {
    greenidUI: any;
    greenidJQuery: any;
  }
}

// Possible errors list: https://vixverify.atlassian.net/wiki/spaces/GREEN/pages/60482940/Faults+and+Errors+V3
// Only included the ones which have been encountered and have been reproduceable
export type GreenIDErrorCode = 'TokenTimeoutFault' | 'InvalidTokenFault' | 'SystemFault';

export type GreenIDFrameProps = {
  greenIdRef: string;
  fetchToken: (greenIdRef: string) => Promise<string>;
  storeResult: (greenIdRef: string) => Promise<void>;
  onSuccess: (greenIdStatus?: GreenIdStatusEnum) => void;
  onCancel: () => void;
  onError: (error: GreenIDErrorCode | Error) => void;
};

export const GreenIDFrame: React.FC<GreenIDFrameProps> = observer((props) => {
  const { greenIdRef, fetchToken, storeResult, onSuccess, onCancel, onError } = props;
  const [token, setToken] = useState('');
  const { addMessageBox } = UIStore.useUIStore;
  const { greenIdError } = useIntercomEvent();
  const { setUserProfile } = UserStore.useUserStore;
  const errorCount = useRef(0);

  const { t } = useTranslation('profile.verification');

  useEffect(() => {
    (async () => {
      if (token) return;
      const fetchedToken = await fetchToken(greenIdRef);
      setToken(fetchedToken);
    })();
  }, [token, greenIdRef]);

  useEffect(() => {
    if (!token || !window.greenidUI) return;

    window.greenidUI.updateSubmitted(false);

    window.greenidJQuery('document').ready(() => {
      window.greenidUI.setup({
        environment: env.GREEN_ID_ENV,
        frameId: 'greenid-container',
        errorCallback: async (newToken: string, error: GreenIDErrorCode) => {
          errorCount.current += 1;

          if (errorCount.current >= 5) {
            greenIdError(error);
            onError(error);
            return;
          }

          switch (error) {
            case 'TokenTimeoutFault':
              addMessageBox({
                content: (
                  <WarningMessageBox
                    title={t('greenIdVerificationModal.labels.timeoutBoxTitle')}
                    content={t('greenIdVerificationModal.labels.timeoutBoxSubTitle')}
                  />
                ),
              });
              break;
            case 'InvalidTokenFault':
            case 'SystemFault':
              // Trigger a token refresh
              setToken('');
              break;
            default:
              greenIdError(error);
              onError(error);
              break;
          }
        },
        sessionCompleteCallback: async (tkn: string) => {
          setToken(tkn);
          try {
            await storeResult(greenIdRef);

            api.endpoints.getProfile.resetCache();
            const profile = await api.endpoints.getProfile();
            setUserProfile(profile?.data?.user.profile);
            onSuccess(profile?.data?.user.profile.verification?.greenid.status);
          } catch (e) {
            Sentry.captureException(e);
          }
        },
        sourceAttemptCallback: (tkn: string) => {
          setToken(tkn);
        },
        sessionCancelledCallback: () => {
          onCancel();
        },
      });

      window.greenidUI.show(env.GREEN_ID_ACCOUNT_ID, env.GREEN_ID_API_KEY, token);
    });
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box position='relative'>
      {/* In some rare cases Green ID fails to initialise even though the scripts (greenidui.min.js)
      have been successfully loaded which results in window.greenidUI being undefined */}
      {!window.greenidUI && (
        <Stack spacing={2}>
          <Notification severity='error' title='Error'>
            <Typography>Something went wrong, try refreshing your browser.</Typography>
          </Notification>
          <Button variant='contained' onClick={() => window.location.reload()}>
            Click to Refresh
          </Button>
        </Stack>
      )}
      <GreenIDContainer className='fs-exclude' id='greenid-container' />
    </Box>
  );
});
