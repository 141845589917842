import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { ContentBanner } from '@global-components/ContentBanner';

import { UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';

import { formatAvoDate, getCountry } from '@Reverification/utils';
import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

export const ReverificationInProgressBanner: React.FC = observer(() => {
  const { navigate } = useNavigateRoute();
  const avo = useAvo();
  const { pathname } = useLocation();
  const { isAU, isNZ, reverificationDaysRemaining, reverificationRequiredBy } = UserStore.useUserStore;
  const { t } = useTranslation('reverifications');

  const Description: React.FC = () => (
    <FlexLayout direction='row' spacing={4}>
      <Body size='small' color='secondary'>
        <Trans
          t={t}
          i18nKey='banners.inProgress.description'
          values={{ days: reverificationDaysRemaining() }}
          components={[<strong key='days' />]}
        />
      </Body>
    </FlexLayout>
  );

  const bannerIcon = <Image image='verification_banner' className='h-64 w-64' usePalette />;

  const onAction = () => {
    avo.ctaClickReveriDvs({
      screen: pathname,
      requiredBy: formatAvoDate(reverificationRequiredBy()),
      country: getCountry({ isAU, isNZ }),
      reveriDvsStatus: 'IN_PROGRESS',
    });
    navigate(NavigationURLs.Reverification);
  };

  return (
    <FlexLayout direction='column'>
      <ContentBanner
        title={t('banners.inProgress.title')}
        description={<Description />}
        icon={bannerIcon}
        actionLabel={t('banners.inProgress.action')}
        onAction={onAction}
      />
    </FlexLayout>
  );
});
