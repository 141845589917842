import React from 'react';
import { useTranslation } from 'react-i18next';

import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { ContentBanner } from '@global-components/ContentBanner';

export const ReverificationInReviewBanner: React.FC = () => {
  const { t } = useTranslation('reverifications');

  const Description: React.FC = () => (
    <FlexLayout direction='row' spacing={4}>
      <Body size='small' color='secondary'>
        {t('banners.inReview.description')}
      </Body>
    </FlexLayout>
  );

  const bannerIcon = <Image image='verification_banner' className='h-64 w-64' usePalette />;

  return (
    <FlexLayout direction='column'>
      <ContentBanner title={t('banners.inReview.title')} description={<Description />} icon={bannerIcon} />
    </FlexLayout>
  );
};
