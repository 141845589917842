import { OnboardingPayload } from '@shared/api';

import { DateTime } from 'luxon';

import { VerificationData } from '../types/Onboarding.types';

type IntersectingKeys = keyof VerificationData & keyof OnboardingPayload;

// Fields that don't require any type transformations
const sharedFields: IntersectingKeys[] = [
  'givenNames',
  'middleNames',
  'surname',
  'country',
  'citizenshipCountry',
  'dualCitizenshipCountry',
  'accountPurpose',
  'sourceOfWealth',
];

export const transformOnboardingPayloadToVerificationData = (data: OnboardingPayload): Partial<VerificationData> => {
  const result: Partial<VerificationData> = {};

  sharedFields.forEach((key) => {
    if (data[key]) {
      // @ts-ignore
      result[key] = data[key];
    }
  });
  if (data.dob) result.dob = DateTime.fromSeconds(data.dob as NonNullable<OnboardingPayload['dob']>);

  const { unit, streetNum, streetName, streetType, suburb, state, postcode, country } = data;
  if (streetNum && streetName && streetType && suburb && state && postcode && country) {
    result.address = {
      flatNumber: unit ?? undefined,
      streetNumber: streetNum,
      streetName,
      streetType,
      postcode: `${postcode}`,
      ...(country === 'AU'
        ? {
            suburb,
            state,
            country: 'AU',
          }
        : {
            suburb,
            // townCity is stored in state field in onboarding table for NZ users
            townCity: state,
            country: 'NZ',
          }),
    };
  }

  return result;
};

export const transformVerificationDataToOnboardingPayload = (data: Partial<VerificationData>) =>
  Object.entries(data).reduce<OnboardingPayload>((acc, [_key, value]) => {
    const key = _key as keyof VerificationData;

    if (sharedFields.includes(key as IntersectingKeys)) {
      // @ts-ignore
      acc[key] = value;
    } else if (key === 'dob') {
      acc.dob = (value as NonNullable<VerificationData['dob']>).toSeconds();
    } else if (key === 'address') {
      const address = value as VerificationData['address'];
      acc.unit = address.flatNumber;
      acc.streetNum = address.streetNumber;
      acc.streetName = address.streetName;
      acc.streetType = address.streetType;
      acc.suburb = address.suburb;
      // Store townCity in state field in onboarding table for NZ users
      acc.state = address.country === 'AU' ? address.state : address.townCity;
      acc.postcode = Number(address.postcode);
    }

    return acc;
  }, {});
