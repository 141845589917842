import { DateTime } from 'luxon';
import { z } from 'zod';

export const DateOfBirthSchema = z.object({
  dateOfBirth: z.any().transform((date, ctx) => {
    if (!(date instanceof DateTime && date.isValid)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid date of birth. Please enter a valid date.',
      });
      return z.NEVER;
    }
    if (date.year < 1900) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please enter a date of birth after the year 1900.',
      });
      return z.NEVER;
    }
    if (date > DateTime.now().minus({ years: 18 })) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'You must be at least 18 years old.',
      });
      return z.NEVER;
    }
    return date;
  }),
});

export type DateOfBirthFields = z.infer<typeof DateOfBirthSchema>;
