import { AddressBrokenUp } from '@shared/api';

import { z } from 'zod';

export const AddressSchema = z.object({
  address: z.intersection(
    z.object({
      flatNumber: z
        .string()
        .optional()
        .transform((value) => value?.trim())
        .pipe(z.string().max(16, 'Unit number is too long - max 16 characters.').optional()),
      streetNumber: z
        .string()
        .transform((value) => value.trim())
        .pipe(
          z.string().min(1, 'Street number is required.').max(16, 'Street number is too long - max 16 characters.'),
        ),
      streetName: z
        .string()
        .transform((value) => value.trim())
        .pipe(z.string().min(1, 'Street name is required.').max(64, 'Street name is too long - max 64 characters.')),
      streetType: z
        .string()
        .transform((value) => value.trim())
        .pipe(z.string().min(1, 'Street type is required.').max(16, 'Street type is too long - max 16 characters.')),
      postcode: z
        .string()
        .transform((value) => value.trim())
        .pipe(
          z
            .string()
            .min(1, 'Postcode is required.')
            .regex(/^\d{4}$/, 'Invalid postcode.'),
        ),
    }),
    z.union([
      z.object({
        suburb: z
          .string()
          .transform((value) => value.trim())
          .pipe(z.string().min(1, 'Suburb is required.').max(64, 'Suburb is too long - max 64 characters.')),
        state: z
          .string()
          .transform((value) => value.trim())
          .pipe(z.string().min(1, 'State is required.').max(64, 'State is too long - max 64 characters.')),
        country: z.literal('AU'),
      }),
      z.object({
        suburb: z
          .string()
          .optional()
          .transform((value) => value?.trim())
          .pipe(z.string().max(64, 'Suburb is too long - max 64 characters.').optional()),
        townCity: z
          .string()
          .transform((value) => value.trim())
          .pipe(z.string().min(1, 'Town / city is required.').max(64, 'Town / city is too long - max 64 characters.')),
        country: z.literal('NZ'),
      }),
    ]),
  ) satisfies { _output: z.ZodSchema<AddressBrokenUp>['_output'] },
});

export type AddressFields = z.infer<typeof AddressSchema>;
