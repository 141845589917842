import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Label } from '@swyftx/aviary/atoms/Label';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { DateOfBirthInput } from '@swyftx/aviary/molecules/DateOfBirthInput/DateOfBirthInput';

import { UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';

import { ReverificationContext } from '@Reverification/Reverification.context';
import { zodResolver } from '@hookform/resolvers/zod';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';

import { BaseReverificationStep } from './components/BaseReverificationStep';
import { DateOfBirthSchema, DateOfBirthFields } from './schemas/DateOfBirthSchema';
import { getAvoEventStepId, formatAvoDate, getCountry } from '../../utils';

export const DateOfBirthStep: React.FC = observer(() => {
  const avo = useAvo();
  const { pathname } = useLocation();
  const { isAU, isNZ, reverificationRequiredBy } = UserStore.useUserStore;

  const {
    dateOfBirth: initialDateOfBirth,
    setDateOfBirth: commitDateOfBirth,
    editingDateOfBirth,
    setCurrentStep,
  } = useContext(ReverificationContext);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<DateOfBirthFields>({
    mode: 'onChange',
    defaultValues: {
      dateOfBirth: initialDateOfBirth,
    },
    resolver: zodResolver(DateOfBirthSchema, undefined, { rawValues: true }),
  });

  const { t } = useTranslation('reverifications');

  const onCancel = () => {
    setCurrentStep('REVIEW_DETAILS');
  };

  const onSubmit = (data: DateOfBirthFields) => {
    avo.confirmDetailsStepCompleted({
      screen: pathname,
      stepId: getAvoEventStepId('DATE_OF_BIRTH'),
      requiredBy: formatAvoDate(reverificationRequiredBy()),
      country: getCountry({ isAU, isNZ }),
    });
    // @ts-ignore need to do this hack to get around strange luxon date transform issue with hook form
    commitDateOfBirth(DateTime.fromObject(data.dateOfBirth?.c));
    setCurrentStep('REVIEW_DETAILS');
  };

  return (
    <BaseReverificationStep heading={t('steps.dateOfBirth.title')} description={t('steps.dateOfBirth.description')}>
      <Controller
        name='dateOfBirth'
        control={control}
        render={({ field }) => (
          <FlexLayout direction='column' spacing={8}>
            <Label>{t('fields.dateOfBirth.label')}</Label>
            <DateOfBirthInput
              // @ts-ignore need to do this hack to get around strange luxon date transform issue with hook form
              value={field.value ? DateTime.fromObject(field.value?.c) : undefined}
              onChange={field.onChange}
              error={errors.dateOfBirth?.message}
            />
          </FlexLayout>
        )}
      />
      {editingDateOfBirth && (
        <FlexLayout direction='row' spacing={12}>
          <Button size='lg' variant='outlined' className='flex-grow' onClick={onCancel}>
            {t('actions.cancel')}
          </Button>
          <Button size='lg' className='flex-grow' onClick={handleSubmit(onSubmit)} disabled={!isValid}>
            {t('actions.update')}
          </Button>
        </FlexLayout>
      )}
      {!editingDateOfBirth && (
        <FlexLayout direction='column'>
          <Button size='lg' onClick={handleSubmit(onSubmit)} disabled={!isValid}>
            {t('actions.continue')}
          </Button>
        </FlexLayout>
      )}
    </BaseReverificationStep>
  );
});
