import { useContext, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { GreenIdStatusEnum, KycStatusEnum, ReverificationStatusEnum } from '@shared/enums';
import entityService from '@shared/services/entityService';
import { StorageKey } from '@shared/storage';
import { AppStore, UserStore } from '@shared/store';
import entityHelpers from '@shared/utils/lib/entityHelpers';

import { useEntityOnboardingApplication } from '@hooks/Entity/useEntityOnboardingApplication';
import { useWithdrawalPermission } from '@hooks/Withdraw/useWithdrawalPermission';
import { DashboardContext } from '@routes/Dashboard';

import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useProfileStatus } from 'src/lib/verification/hooks/useProfileStatus';

export const usePageBanner = () => {
  const { isDemo } = AppStore.useAppStore;
  const { editMode } = useContext(DashboardContext);
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const location = useLocation();
  const {
    userProfile,
    userHasCompletedOnboarding,
    isEntity,
    isRestricted,
    userHasPendingIdVerification,
    sourceOfWealthStatus,
    entityAccounts,
    balances,
    entityMembers,
  } = UserStore.useUserStore;
  const { isVerified, hasDeposited, hasTraded } = useProfileStatus();
  const hasShownEntityExpressionOfInterest = userProfile?.userSettings?.applyForEntityAccount;
  const reverification = userProfile?.accountStatus?.reverification ?? null;

  const [refereePromptDismissed, setRefereePromptDismissed] = useState<boolean>(
    Boolean(JSON.parse(localStorage.getItem(StorageKey.DISMISS_REFER_A_FRIEND_BANNER) || 'false')),
  );

  const { entityHistory } = useEntityOnboardingApplication();
  const hasSubmittedEntityApplication = entityHistory.some((application) => application.status === 'SUBMITTED');
  const hasDismissedEntityApplicationBanner =
    localStorage.getItem(StorageKey.DISMISS_ENTITY_APPLICATION_SUBMITTED_BANNER) === 'true';
  const hasDismissedEntityApplicationApprovedBanner =
    localStorage.getItem(StorageKey.DISMISS_ENTITY_APPLICATION_APPROVED_BANNER) === 'true';
  const hasDismissedEntityVerifyMembersBanner =
    localStorage.getItem(StorageKey.DISMISS_ENTITY_VERIFY_MEMBERS_BANNER) === 'true';
  const hasDismissedEntityDepositFundsBanner =
    localStorage.getItem(StorageKey.DISMISS_ENTITY_DEPOSIT_FUNDS_BANNER) === 'true';

  const withdrawalBlockedStatus = useWithdrawalPermission('BTC');
  const showWithdrawalsBlockedPrompt = withdrawalBlockedStatus.blocked;

  const userIsInProfile = useMemo(() => location.pathname.includes(NavigationURLs.Profile), [location.pathname]);
  const sowStatus = sourceOfWealthStatus();
  const isEntityAccount = isEntity();

  /**
   * Show's the source of wealth banner if source of wealth is required and the feature flag is on
   */
  const showSourceOfWealthBanner = useMemo(
    () =>
      isFeatureEnabled(AppFeature.SourceOfWealth) &&
      [KycStatusEnum.REQUIRED, KycStatusEnum.STARTED].includes(sowStatus) &&
      !userIsInProfile,
    [isFeatureEnabled, sowStatus, userIsInProfile],
  );

  /**
   * Shows the referee prompt banner if the user has a friend referral signup bonus and has not dismissed the banner
   */
  const showRefereePromptBanner = useMemo(() => {
    const isFriendReferral = userProfile?.userSettings?.signupBonus?.type === 'friendReferral';
    return isFriendReferral && !refereePromptDismissed;
  }, [refereePromptDismissed, userProfile?.userSettings?.signupBonus?.type]);

  /**
   * Shows the verification pending banner if the user has a pending id verification
   */
  const showVerificationPendingBanner = useMemo(() => userHasPendingIdVerification(), [userHasPendingIdVerification]);

  /**
   * Shows reverification banners if the user has a pending, in-progress or lapsed reverification
   */
  const showReverificationPendingBanner = useMemo(
    () => isFeatureEnabled(AppFeature.Reverifications) && reverification?.status === ReverificationStatusEnum.REQUESTED,
    [isFeatureEnabled, reverification],
  );
  const showReverificationInProgressBanner = useMemo(
    () =>
      isFeatureEnabled(AppFeature.Reverifications) &&
      reverification?.status === ReverificationStatusEnum.IN_PROGRESS &&
      reverification?.greenIdRequired &&
      (reverification?.greenIdStatus === GreenIdStatusEnum.NOT_STARTED ||
        reverification?.greenIdStatus === GreenIdStatusEnum.IN_PROGRESS),
    [isFeatureEnabled, reverification],
  );
  const showReverificationInReviewBanner = useMemo(
    () =>
      isFeatureEnabled(AppFeature.Reverifications) &&
      reverification?.status === ReverificationStatusEnum.IN_PROGRESS &&
      reverification?.greenIdRequired &&
      reverification?.greenIdStatus === GreenIdStatusEnum.PENDING_REVIEW,
    [isFeatureEnabled, reverification],
  );
  const showReverificationLapsedBanner = useMemo(
    () => isFeatureEnabled(AppFeature.Reverifications) && reverification?.status === ReverificationStatusEnum.LAPSED,
    [isFeatureEnabled, reverification],
  );

  /**
   * Shows the restricted banner if the user is restricted
   */
  const showRestrictedBanner = useMemo(() => isRestricted(), [isRestricted]);

  /**
   * Shows the crypto withdrawals require photo verification banner if the user has not completed photo verification
   */
  const hasNotCompletedPhotoVerification = useMemo(
    () =>
      withdrawalBlockedStatus.message.toLowerCase() ===
      'please complete photo verification to enable crypto withdrawals on your account',
    [withdrawalBlockedStatus.message],
  );

  /**
   * Shows the withdrawals blocked banner if the user has not completed photo verification
   */
  const showWithdrawalsBlockedBanner = useMemo(() => {
    const cryptoBlocked = withdrawalBlockedStatus.message.toLowerCase().indexOf('proceed with withdrawing') > -1;
    if (showWithdrawalsBlockedPrompt && !cryptoBlocked && !hasNotCompletedPhotoVerification) return true;
    return false;
  }, [hasNotCompletedPhotoVerification, showWithdrawalsBlockedPrompt, withdrawalBlockedStatus.message]);

  /**
   * Shows the entity application submitted banner if the user has submitted an entity application and has not dismissed
   * the banner
   */
  const showEntityApplicationSubmittedBanner = useMemo(
    () =>
      isFeatureEnabled(AppFeature.EntityOnboarding) &&
      hasSubmittedEntityApplication &&
      !hasDismissedEntityApplicationBanner,
    [hasDismissedEntityApplicationBanner, hasSubmittedEntityApplication, isFeatureEnabled],
  );

  /**
   * Shows the personal account setup / verification widget banner if the user is not an entity, has not expressed interest
   * in being an entity, and has not fully completed account setup
   */
  const showPersonalAccountSetupBanner = useMemo(
    () =>
      (!isVerified || !hasDeposited || !hasTraded) &&
      !isEntityAccount &&
      !hasShownEntityExpressionOfInterest &&
      !userIsInProfile,
    [hasDeposited, hasShownEntityExpressionOfInterest, hasTraded, isEntityAccount, isVerified, userIsInProfile],
  );

  /**
   * Shows the onboarding banner if the user has not completed onboarding
   */
  const showOnboardingBanner = useMemo(() => {
    if (isEntityAccount && !entityService.isEntityVerified()) {
      return true;
    }
    if (!isDemo && !editMode && userProfile && !userHasCompletedOnboarding()) {
      return true;
    }
    return false;
  }, [editMode, isDemo, isEntityAccount, userHasCompletedOnboarding, userProfile]);

  /**
   * Shows the entity application onboarding banner if the user has opted in to make an account at time of sign up,
   * has completed onboarding and has not yet submitted an entity application
   */
  const showEntityApplicationOnboardingBanner = useMemo(() => {
    if (
      isFeatureEnabled(AppFeature.EntityOnboarding) &&
      userProfile?.userSettings.applyForEntityAccount &&
      !userIsInProfile
    ) {
      return true;
    }
    return false;
  }, [isFeatureEnabled, userIsInProfile, userProfile?.userSettings.applyForEntityAccount]);

  /**
   * Shows the entity application approved banner if their most recent entity application is of status: COMPLETED,
   * they have an entity account linked to their account and they have not dismissed the banner
   */
  const showEntityApplicationApprovedBanner = useMemo(() => {
    if (
      isFeatureEnabled(AppFeature.EntityOnboarding) &&
      entityHistory?.length &&
      entityHistory[0].status === 'COMPLETED' &&
      entityAccounts.length &&
      !hasDismissedEntityApplicationApprovedBanner &&
      !userIsInProfile
    ) {
      return true;
    }
  }, [
    entityAccounts.length,
    entityHistory,
    hasDismissedEntityApplicationApprovedBanner,
    isFeatureEnabled,
    userIsInProfile,
  ]);

  /**
   * Shows the verify entity banner if the user has unverified members
   */
  const showVerifyEntityBanner = useMemo(
    () =>
      isEntityAccount &&
      !entityService.isEntityVerified() &&
      entityHelpers.isAllMembersVerified(entityMembers) &&
      !hasDismissedEntityVerifyMembersBanner,
    [entityMembers, hasDismissedEntityVerifyMembersBanner, isEntityAccount],
  );

  /**
   * Shows the deposit funds banner for an entity account if the user is an entity,
   * has no balances and all members are verified
   */
  const showEntityAccountDepositFundsBanner = useMemo(
    () =>
      isEntityAccount &&
      Object.values(balances).length === 0 &&
      entityHelpers.isAllMembersVerified(entityMembers) &&
      entityService.isEntityVerified() &&
      !hasDismissedEntityDepositFundsBanner &&
      !userIsInProfile,
    [balances, entityMembers, hasDismissedEntityDepositFundsBanner, isEntityAccount, userIsInProfile],
  );

  return {
    editMode,
    showPersonalAccountSetupBanner,
    showSourceOfWealthBanner,
    showVerificationPendingBanner,
    showReverificationPendingBanner,
    showReverificationInProgressBanner,
    showReverificationInReviewBanner,
    showReverificationLapsedBanner,
    showRefereePromptBanner,
    setRefereePromptDismissed,
    showRestrictedBanner,
    showCryptoWithdrawalsRequiresPhotoVerificationBanner: hasNotCompletedPhotoVerification,
    showWithdrawalsBlockedBanner,
    withdrawalsBlockedMessage: withdrawalBlockedStatus.message,
    showEntityApplicationSubmittedBanner,
    showOnboardingBanner,
    showEntityApplicationOnboardingBanner,
    showEntityApplicationApprovedBanner,
    showEntityAccountDepositFundsBanner,
    showVerifyEntityBanner,
  };
};
