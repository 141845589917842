import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { MobileDatePicker } from '@mui/x-date-pickers';

import { Button, Checkbox, Input, Modal, Select, SelectItem, Stack, Typography } from '@swyftx/react-web-design-system';

import { CountryDropdown } from '@global-components/Dropdown';
import { Form } from '@global-components/Form';
import { NameInput } from '@global-components/Input';
import { AddressInput } from '@global-components/Input/AddressInput/AddressInput';
import { SuccessMessageBox } from '@global-components/message-boxes/SuccessMessageBox';

import { api, AddressBrokenUp } from '@shared/api';
import { GreenIdData } from '@shared/api/@types/verification';
import env from '@shared/config';
import { AccountPurpose, CountriesEnum, GreenIdStatusEnum, SourceOfWealth, SuggestedCountry } from '@shared/enums';
import { UIStore, UserStore } from '@shared/store';
import { uuid } from '@shared/utils';

import countries from '@utils/countries/countries';

import { ProfileVerificationContext } from '@Profile/subroutes/Verification/ProfileVerification.context';
import { CancelVerificationModal } from '@Profile/subroutes/Verification/components/VerificationMethod/CancelVerificationModal';
import { VerificationStep } from '@Profile/subroutes/Verification/types';

import ct from 'countries-and-timezones';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';

import { isValidCountry } from '../VerificationMethod/VerifyIdentity/VerifyIdentity.util';

const PersonalDetailsModal: React.FC = observer(() => {
  const { setVerificationStep } = useContext(ProfileVerificationContext);
  const { userProfile, setGreenIdInfo } = UserStore.useUserStore;
  const userCountry = userProfile?.address?.country;
  const { addMessageBox } = UIStore.useUIStore;
  const [givenNames, setGivenNames] = useState<string>('');
  const [middleNames, setMiddleNames] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [address, setAddress] = useState<AddressBrokenUp | null>();
  const [dateOfBirth, setDateOfBirth] = useState<DateTime | null>(null);
  const [dualCitizenship, setDualCitizenship] = useState<boolean>(false);
  const [citizenshipCountry, setCitizenshipCountry] = useState<CountriesEnum>();
  const [dualCitizenshipCountry, setDualCitizenshipCountry] = useState<CountriesEnum>();

  const [sourceOfWealth, setSourceOfWealth] = useState<SourceOfWealth>();
  const [accountPurpose, setAccountPurpose] = useState<AccountPurpose>();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [verifyClose, setVerifyClose] = useState<boolean>(false);

  const { t } = useTranslation('profile.verification', { keyPrefix: 'personalDetailsModal' });

  const sessionId = useRef(uuid());

  const getAccountPurpose = (): SelectItem[] =>
    Object.values(AccountPurpose).map((purpose: string) => ({
      label: t(`accountPurpose.${purpose}` as any),
      value: purpose,
    }));

  const getSourceOfWealth = (): SelectItem[] =>
    Object.values(SourceOfWealth).map((source: string) => ({
      label: t(`sourceOfWealth.${source}` as any),
      value: source,
    }));

  const [country, setCountry] = useState<CountriesEnum>(
    (ct.getCountriesForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)[0]?.id as CountriesEnum) ||
      CountriesEnum.AU,
  );
  const [matchingDetails, setMatchingDetails] = useState<boolean>(false);

  const onVerifyClose = () => {
    setVerifyClose(true);
  };

  const onClose = (closeParent?: boolean) => {
    setVerifyClose(false);
    if (closeParent) setVerificationStep(VerificationStep.None);
  };

  const handleAddressChange = (newAddress: AddressBrokenUp | null) => {
    setAddress(newAddress);
    sessionId.current = uuid();
  };

  const onContinue = async () => {
    if (!address || !accountPurpose || !sourceOfWealth) return;

    const greenIdData: GreenIdData = {
      accountId: `${env.GREEN_ID_ACCOUNT_ID}`,
      apiCode: `${env.GREEN_ID_API_KEY}`,
      givenNames,
      middleNames,
      surname,
      ruleId: countries[country].ruleSet ?? '',
      email: userProfile?.email ?? '',
      citizenship: citizenshipCountry,
      citizenshipDual: dualCitizenship ? dualCitizenshipCountry : undefined,
      purposeOfAccount: accountPurpose,
      sourceOfWealth,
      dob: {
        day: dateOfBirth?.day ?? 0,
        month: dateOfBirth?.month ?? 0,
        year: dateOfBirth?.year ?? 0,
      },
      ...address,
    };

    const result = await api.endpoints.setupNewGreenID({ data: { ...greenIdData } });
    setGreenIdInfo(result.data.greenIdRef, GreenIdStatusEnum.IN_PROGRESS);
    setSubmitting(false);
    setVerificationStep(VerificationStep.GreenID);
    addMessageBox({
      content: <SuccessMessageBox title='Success' content={t('labels.successTitle')} />,
    });

    // Sync data submitted from this form into onboarding wizard
    api.endpoints
      .syncOnboardingData({
        data: {
          givenNames,
          middleNames,
          surname,
          dob: dateOfBirth?.toSeconds(),
          country,
          unit: address.flatNumber,
          streetNum: address.streetNumber,
          streetName: address.streetName,
          streetType: address.streetType,
          suburb: address.suburb,
          state: 'state' in address ? address.state : address.townCity,
          postcode: address.postcode ? Number(address.postcode) : null,
          citizenshipCountry,
          dualCitizenshipCountry,
          accountPurpose,
          sourceOfWealth,
        },
      })
      .catch(() => {
        // Okay to fail silently here
      });
  };

  useEffect(() => {
    if (userCountry && isValidCountry(userCountry)) {
      setCountry(userCountry);
    }
  }, [userCountry]);

  if (!isValidCountry(userCountry)) return null;

  return (
    <>
      <Modal
        PII
        HeaderProps={{
          title: t('title'),
          dismissible: true,
          divider: true,
        }}
        FooterProps={{
          content: (
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='flex-end'
              spacing={3}
              sx={{ color: 'text.secondary' }}
            >
              <Button variant='text' color='inherit' onClick={onVerifyClose}>
                {t('buttonLabels.skip')}
              </Button>
              <Button
                disableElevation
                disabled={
                  !givenNames ||
                  !surname ||
                  !country ||
                  !address ||
                  !dateOfBirth ||
                  !citizenshipCountry ||
                  (dualCitizenship && !dualCitizenshipCountry) ||
                  !sourceOfWealth ||
                  !accountPurpose ||
                  !matchingDetails
                }
                variant='contained'
                type='submit'
                form='personalDetailsModal.form'
                loading={submitting}
              >
                {t('buttonLabels.continue')}
              </Button>
            </Stack>
          ),
        }}
        sx={{
          visibility: verifyClose ? 'hidden' : 'visible',
          width: '600px',
          maxHeight: 'calc(100% - 6rem)',
          '.MuiCardContent-root': { height: '100%' },
        }}
        onClose={onVerifyClose}
        open
        variant='BottomSheet'
      >
        <>
          <Typography marginBottom={2}>{t('subTitle')}</Typography>
          <Form
            id='personalDetailsModal.form'
            onSubmit={onContinue}
            setSubmitting={setSubmitting}
            submitting={submitting}
            hideButton
          >
            <NameInput
              required
              id='personalDetailsModal.form.givenNames'
              type='FirstName'
              onChange={(e) => setGivenNames(e.target.value)}
              value={givenNames}
              placeholder={t('labels.firstName')}
            />
            <NameInput
              id='personalDetailsModal.form.middleNames'
              type='MiddleName'
              required={false}
              onChange={(e) => setMiddleNames(e.target.value)}
              value={middleNames}
              placeholder={t('labels.middleNames')}
            />
            <NameInput
              required
              id='personalDetailsModal.form.lastName'
              type='LastName'
              onChange={(e) => setSurname(e.target.value)}
              value={surname}
              placeholder={t('labels.lastName')}
            />
            <CountryDropdown
              required
              id='personalDetailsModal.form.country'
              value={country}
              onChange={setCountry}
              label={t('labels.country')}
            />
            <AddressInput
              required
              sessionUuid={sessionId.current}
              country={country as SuggestedCountry}
              onSelectAddress={handleAddressChange}
            />
            <MobileDatePicker
              label={
                <Stack direction='row'>
                  <Typography fontWeight={600} fontSize={14}>
                    {t('labels.dob')}
                  </Typography>
                  <Typography color='error'>*</Typography>
                </Stack>
              }
              inputFormat='dd/MM/yyyy'
              value={dateOfBirth}
              onChange={setDateOfBirth}
              renderInput={(params: any) => <Input {...params} placeholder={t('labels.dobPlaceholder')} />}
            />
            <FormControl sx={{ width: '100%', marginTop: 2 }}>
              <FormLabel
                id='demo-row-radio-buttons-group-label'
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: 'text.primary',
                  '&.MuiFormLabel-root': { color: 'text.primary' },
                }}
              >
                <Stack direction='row'>
                  <Typography fontWeight={600} fontSize={14}>
                    {t('labels.haveDualCitizenship')}
                  </Typography>
                  <Typography color='error'>*</Typography>
                </Stack>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                onChange={(_, value) => setDualCitizenship(value === 'true')}
                value={dualCitizenship}
              >
                <FormControlLabel value='true' control={<Radio />} label='Yes' />
                <FormControlLabel value='false' control={<Radio />} label='No' />
              </RadioGroup>
            </FormControl>
            <CountryDropdown
              required
              id='personalDetailsModal.form.citizenship'
              value={citizenshipCountry}
              onChange={setCitizenshipCountry}
              placeholder={t('labels.selectCountryOfCitizenship')}
              label={t('labels.whatIsYourCountryOfCitizenship')}
            />
            {dualCitizenship === true && (
              <CountryDropdown
                required
                id='personalDetailsModal.form.dualCitizenship'
                value={dualCitizenshipCountry}
                placeholder={t('labels.selectCountryOfCitizenship')}
                onChange={setDualCitizenshipCountry}
                label=''
              />
            )}
            <Select
              label={
                <Stack direction='row'>
                  <Typography fontWeight={600} fontSize={14}>
                    {t('labels.sourceOfWealth')}
                  </Typography>
                  <Typography color='error'>*</Typography>
                </Stack>
              }
              items={getSourceOfWealth()}
              placeholder={t('labels.sourceOfWealthPlaceholder')}
              value={sourceOfWealth}
              sx={{
                backgroundColor: 'background.default',
                fontSize: 14,
                ':hover': { backgroundColor: 'action.hover' },
                width: '100%',
              }}
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: 2,
                  '.MuiTypography-root': {
                    fontSize: 14,
                  },

                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'divider',
                  },

                  ':hover': {
                    '.MuiOutlinedInput-root': {
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'action.hover',
                      },
                    },
                  },

                  ':focus-visible': {
                    '.MuiOutlinedInput-root': {
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'action.hover',
                      },
                    },
                  },
                },
              }}
              onChange={(e) => setSourceOfWealth(e.target.value as SourceOfWealth)}
            />
            <Select
              label={
                <Stack direction='row'>
                  <Typography fontWeight={600} fontSize={14}>
                    {t('labels.accountPurpose')}
                  </Typography>
                  <Typography color='error'>*</Typography>
                </Stack>
              }
              items={getAccountPurpose()}
              value={accountPurpose}
              placeholder={t('labels.accountPurposePlaceholder')}
              onChange={(e) => setAccountPurpose(e.target.value as AccountPurpose)}
              sx={{
                backgroundColor: 'background.default',
                fontSize: 14,
                ':hover': { backgroundColor: 'action.hover' },
                width: '100%',
              }}
              formControlProps={{
                placeholder: t('labels.accountPurposePlaceholder'),
                sx: {
                  width: '100%',
                  marginTop: 2,
                  '.MuiTypography-root': {
                    fontSize: 14,
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'divider',
                  },

                  ':hover': {
                    '.MuiOutlinedInput-root': {
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'action.hover',
                      },
                    },
                  },

                  ':focus-visible': {
                    '.MuiOutlinedInput-root': {
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'action.hover',
                      },
                    },
                  },
                },
              }}
            />
          </Form>
          <Box
            id='personalDetailsModal.form.matchingDetails.container'
            sx={{
              marginBottom: 2,
              marginTop: 2,
              color: 'text.primary',
              '.MuiTypography-root': { fontSize: 12 },
            }}
          >
            <Checkbox
              id='personalDetailsModal.form.matchingDetails'
              checked={matchingDetails}
              label={t('labels.matchingDetails')}
              onChange={(_, checked: boolean) => setMatchingDetails(checked)}
            />
          </Box>
        </>
      </Modal>
      {verifyClose && <CancelVerificationModal onClose={onClose} />}
    </>
  );
});

export { PersonalDetailsModal };
