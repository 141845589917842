import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { AddInCircleFilled } from '@swyftx/aviary/icons/filled';
import { Input, Stack, TextFieldProps, Typography } from '@swyftx/react-web-design-system';

import { api, AddressBrokenUp } from '@shared/api';
import { SuggestedCountry } from '@shared/enums';

import { useDebounce } from 'react-use';

import { AddressInputLabel } from './AddressInputLabel';
import { ManualAddressForm } from './ManualAddressForm';
import { ManualAddressModal } from './ManualAddressModal';

export type AutocompleteOption = {
  label: string;
  placeId: string;
};

type AddressInputFormProps = {
  attachForm?: (id: string, validate: () => Promise<boolean>) => void;
  success?: boolean;
  value?: string;
  country: SuggestedCountry;
  sessionUuid: string;
  onSelectAddress: (option: AddressBrokenUp | null) => void;
  helperText?: string;
  fieldErrors?: any;
  inlineManualInput?: boolean; // Render manual input fields in place of search field instead of in modal
} & TextFieldProps;

const ADDRESS_DEBOUNCE = 250;
const MANUAL_PLACE_ID = 'manual';

const AddressInput: React.FC<AddressInputFormProps> = ({
  value,
  sessionUuid,
  onSelectAddress,
  country,
  required,
  error,
  helperText,
  fieldErrors,
  inputRef,
  inlineManualInput,
  sx,
}) => {
  const [address, setAddress] = useState<string | undefined>(value);
  const [suggestions, setSuggestions] = useState<AutocompleteOption[]>([]);
  const [manualInput, setManualInput] = useState<boolean>(false);

  const [debouncedAddress, setDebouncedAddress] = useState<string>('');
  const { t } = useTranslation('common');

  useDebounce(
    () => {
      if (address !== undefined) setDebouncedAddress(address);
    },
    ADDRESS_DEBOUNCE,
    [address],
  );

  const onUpdateAddress = (_: any, value: string) => {
    // Workaround so the "can't find address" message isn't displayed in the input field
    if (value === t('addressInput.cantFindAddress')) return;
    setAddress(value);
  };

  const onSelectManualAddress = (address: AddressBrokenUp, close: boolean) => {
    const { flatNumber, streetNumber, streetName, streetType, postcode } = address;

    const suburbCityState =
      address.country === 'AU'
        ? [address.suburb, address.state].join(' ')
        : [address.suburb, address.townCity].filter((v) => !!v).join(' ');

    const unit = `${flatNumber ? `U ${flatNumber} ` : ''}`;
    const street = `${streetNumber} ${streetName.toUpperCase()} ${streetType.toUpperCase()}`;
    const suburbCityStatePostcode = `${suburbCityState.toUpperCase()} ${postcode}`;

    setAddress(`${unit}${street}, ${suburbCityStatePostcode}`);
    if (close) setManualInput(false);
    onSelectAddress(address);
  };

  useEffect(() => {
    const searchAddress = async () => {
      const results = await api.endpoints.getAddressSuggestion({
        query: {
          search: debouncedAddress,
          country,
          session: sessionUuid,
        },
      });

      const suggestions = results.data.map((suggestion) => ({
        label: suggestion.address,
        placeId: suggestion.placeId,
      }));

      suggestions.push({
        label: t('addressInput.cantFindAddress'),
        placeId: MANUAL_PLACE_ID,
      });

      setSuggestions(suggestions);
    };

    if (debouncedAddress) {
      searchAddress();
    }
  }, [debouncedAddress]);

  return (
    <>
      {!inlineManualInput && manualInput && (
        <ManualAddressModal
          onClose={() => setManualInput(false)}
          country={country}
          onAddManualAddress={(address) => onSelectManualAddress(address, true)}
        />
      )}
      {inlineManualInput && manualInput && (
        <ManualAddressForm
          country={country}
          onChange={(address) => onSelectManualAddress(address, false)}
          errors={fieldErrors}
        />
      )}
      {!(inlineManualInput && manualInput) && (
        <Autocomplete
          filterOptions={(x) => x}
          sx={{ width: '100%', marginBottom: 2, ...sx }}
          options={suggestions}
          autoComplete
          inputValue={address}
          includeInputInList
          filterSelectedOptions
          renderOption={(props, option) => (
            <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...(props as any)}>
              {option.placeId !== MANUAL_PLACE_ID && option.label}
              {option.placeId === MANUAL_PLACE_ID && (
                <Stack direction='row' spacing={1} alignItems='center'>
                  <AddInCircleFilled className='h-20 w-20' />
                  <Typography PII sx={{ color: 'primary.main' }}>
                    {option.label}
                  </Typography>
                </Stack>
              )}
            </Box>
          )}
          onChange={async (_, suggestion: AutocompleteOption | null) => {
            if (!suggestion) return;

            if (suggestion.placeId === MANUAL_PLACE_ID) {
              setManualInput(true);
              setAddress('');
              return;
            }

            const results = await api.endpoints.selectAddressSuggestion({
              params: { placeId: suggestion.placeId },
              query: { session: sessionUuid },
            });

            setAddress(suggestion.label);
            onSelectAddress(results.data);
          }}
          onInputChange={onUpdateAddress}
          renderInput={(params) => (
            <Input
              PII
              inputRef={inputRef}
              {...params}
              label={<AddressInputLabel required={required} />}
              placeholder={t('addressInput.placeholder')}
              value={address}
              error={error}
              helperText={helperText}
              inputProps={{
                ...params.inputProps,
                value: address,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      )}
    </>
  );
};

export { AddressInput };
