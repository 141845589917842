import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { useAvo } from '@hooks/Avo/useAvo';
import { useRiskified } from '@hooks/useRiskified/useRiskified';

type Props = {
  pageName?: string;
  assetCode?: string;
  assetId?: number;
};

const useTrackPageView = ({ pageName = '', assetCode, assetId }: Props = {}) => {
  const avo = useAvo();
  const isXs = useTailwindBreakpoint('xs');
  const { pathname } = useLocation();
  const { riskifiedReady } = useRiskified();

  useEffect(() => {
    avo.pageViewed({
      screen: pathname,
      customDestinationPageName_: pageName,
      layout: isXs ? 'mobile' : 'desktop',
      assetCode,
      assetId,
    });
  }, [assetCode, assetId, avo, isXs, pageName, pathname]);

  useEffect(() => {
    riskifiedReady?.then(() => {
      if (typeof window.RISKX?.go === 'function') {
        window.RISKX.go(pageName);
      }
    });
  }, [pageName, riskifiedReady]);
};

export { useTrackPageView };
