import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import {
  RefereeBanner,
  OnboardingBanner,
  ReverificationInProgressBanner,
  ReverificationInReviewBanner,
  ReverificationLapsedBanner,
  ReverificationPendingBanner,
  SourceOfWealthBanner,
} from '@global-components/Banners';
import { VerificationPendingBanner } from '@global-components/Banners/VerificationPendingBanner';
import { CryptoNotYetUnlockedBanner } from '@global-components/Modals/CryptoNotYetUnlockedBanner/CryptoNotYetUnlockedBanner';
import { WithdrawalsBlockedBanner } from '@global-components/Modals/WithdrawalsBlockedBanner/WithdrawalsBlockedBanner';
import { RestrictedBanner } from '@global-components/RestrictedBanner';

import { EntityAccountDepositFundsBanner } from '@routes/EntityOnboarding/banners/EntityAccountDepositFundsBanner';
import { EntityAccountVerifyMembersBanner } from '@routes/EntityOnboarding/banners/EntityAccountVerifyMembersBanner';
import { EntityApplicationApprovedBanner } from '@routes/EntityOnboarding/banners/EntityApplicationApprovedBanner';
import { EntityApplicationOnboardingBanner } from '@routes/EntityOnboarding/banners/EntityApplicationOnboardingBanner';
import { EntityApplicationSubmittedBanner } from '@routes/EntityOnboarding/banners/EntityApplicationSubmittedBanner';

import { observer } from 'mobx-react-lite';
import { VerificationWidget } from 'src/lib/verification/widgets';

import { usePageBanner } from './usePageBanner';

export type PageBannerProps = {
  path?: string | readonly string[];
  friendlyName?: string;
};

const PageBanner: React.FC<PageBannerProps> = observer(() => {
  const {
    editMode,
    showPersonalAccountSetupBanner,
    showSourceOfWealthBanner,
    showVerificationPendingBanner,
    showReverificationPendingBanner,
    showReverificationInProgressBanner,
    showReverificationInReviewBanner,
    showReverificationLapsedBanner,
    showRefereePromptBanner,
    setRefereePromptDismissed,
    showRestrictedBanner,
    showCryptoWithdrawalsRequiresPhotoVerificationBanner,
    showWithdrawalsBlockedBanner,
    withdrawalsBlockedMessage,
    showEntityApplicationSubmittedBanner,
    showOnboardingBanner,
    showEntityApplicationOnboardingBanner,
    showEntityApplicationApprovedBanner,
    showEntityAccountDepositFundsBanner,
    showVerifyEntityBanner,
  } = usePageBanner();

  /**
   * Calculates which banner item to show based on the current state of the user.
   * These are ranked in order of significance as we only show one to the user at any given time.
   */
  const bannerItem = useMemo(() => {
    if (editMode) return null;

    if (showReverificationPendingBanner) {
      return <ReverificationPendingBanner />;
    }

    if (showReverificationInProgressBanner) {
      return <ReverificationInProgressBanner />;
    }

    if (showReverificationInReviewBanner) {
      return <ReverificationInReviewBanner />;
    }

    if (showReverificationLapsedBanner) {
      return <ReverificationLapsedBanner />;
    }

    if (showSourceOfWealthBanner) {
      return <SourceOfWealthBanner />;
    }

    if (showEntityApplicationOnboardingBanner) {
      return <EntityApplicationOnboardingBanner />;
    }

    if (showPersonalAccountSetupBanner) {
      return <VerificationWidget />;
    }

    if (showVerificationPendingBanner) {
      return <VerificationPendingBanner />;
    }

    if (showRefereePromptBanner) {
      return <RefereeBanner setRefereeBannerDismissed={setRefereePromptDismissed} />;
    }

    if (showRestrictedBanner) {
      return <RestrictedBanner />;
    }

    if (showCryptoWithdrawalsRequiresPhotoVerificationBanner) {
      return <CryptoNotYetUnlockedBanner />;
    }

    if (showWithdrawalsBlockedBanner) {
      return <WithdrawalsBlockedBanner message={withdrawalsBlockedMessage} />;
    }

    if (showEntityApplicationApprovedBanner) {
      return <EntityApplicationApprovedBanner />;
    }

    if (showEntityApplicationSubmittedBanner) {
      return <EntityApplicationSubmittedBanner />;
    }

    if (showOnboardingBanner) {
      return <OnboardingBanner />;
    }

    if (showVerifyEntityBanner) {
      return <EntityAccountVerifyMembersBanner />;
    }

    if (showEntityAccountDepositFundsBanner) {
      return <EntityAccountDepositFundsBanner />;
    }

    return null;
  }, [
    editMode,
    showEntityApplicationOnboardingBanner,
    showPersonalAccountSetupBanner,
    showSourceOfWealthBanner,
    showVerificationPendingBanner,
    showRefereePromptBanner,
    showRestrictedBanner,
    showCryptoWithdrawalsRequiresPhotoVerificationBanner,
    showWithdrawalsBlockedBanner,
    showEntityApplicationApprovedBanner,
    showEntityApplicationSubmittedBanner,
    showOnboardingBanner,
    showVerifyEntityBanner,
    showEntityAccountDepositFundsBanner,
    setRefereePromptDismissed,
    withdrawalsBlockedMessage,
  ]);

  if (!bannerItem) return null;

  return (
    <FlexLayout direction='column' className='w-full @container'>
      {bannerItem}
    </FlexLayout>
  );
});

PageBanner.displayName = 'Page Banner';

export default PageBanner;
