import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, RouteObject } from 'react-router-dom';

import { UserStore } from '@shared/store';

import PrivateRoute from '@routes/PrivateRoute';

import Reverification from '@Reverification/Reverification';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { SwyftxAppShellCustomHeader } from 'src/lib/layout/SwyftxAppShellCustomHeader';
import { NavigationURLs } from 'src/lib/navigation/types';

import { ReverificationProvider } from './Reverification.context';
import { HeaderExitButton } from './components';

const ReverificationRoute: React.FC = () => {
  const { isEntity } = UserStore.useUserStore;
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const { t } = useTranslation('reverifications');

  if (!isFeatureEnabled(AppFeature.Reverifications) || isEntity()) {
    return <Navigate to={NavigationURLs.Dashboard} />;
  }

  return (
    <SwyftxAppShellCustomHeader headerItemOverride={<HeaderExitButton text={t('actions.exit')} />}>
      <PrivateRoute
        element={
          <ReverificationProvider>
            <Reverification />
          </ReverificationProvider>
        }
      />
    </SwyftxAppShellCustomHeader>
  );
};

export const ReverificationRoutes: RouteObject[] = [
  {
    path: NavigationURLs.Reverification,
    element: <ReverificationRoute />,
  },
];
