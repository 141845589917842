import { ReverificationStep } from '../Reverification.context';

type Predicate = () => boolean;

export const getAvoEventStepId = (step: Exclude<ReverificationStep, 'GREEN_ID'> | 'OTP_CODE'): string => {
  switch (step) {
    case 'DATE_OF_BIRTH':
      return 'reverificationConfirmDOBStep';
    case 'REVIEW_DETAILS':
      return 'reverificationConfirmDetailsStep';
    case 'UPDATE_NAME':
      return 'reverificationEditNameStep';
    case 'UPDATE_ADDRESS':
      return 'reverificationEditAddressStep';
    case 'OTP_CODE':
      return 'reverificationOtpAuthenticationStep';
  }
};

export const formatAvoDate = (timestamp: number): string => {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).padStart(4, '0');
  return `${day} ${month} ${year}`;
};

export const getCountry = ({ isAU, isNZ }: { isAU: Predicate; isNZ: Predicate }): string => {
  if (isAU()) return 'AU';
  if (isNZ()) return 'NZ';
  return 'Other';
};
