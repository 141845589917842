import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { FormInput } from '@swyftx/aviary/molecules/FormInput/FormInput';

import { UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';

import { ReverificationContext } from '@Reverification/Reverification.context';
import { zodResolver } from '@hookform/resolvers/zod';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';

import { BaseReverificationStep } from './components/BaseReverificationStep';
import { NameSchema, NameFields } from './schemas/NameSchema';
import { getAvoEventStepId, formatAvoDate, getCountry } from '../../utils';

export const UpdateNameStep: React.FC = observer(() => {
  const avo = useAvo();
  const { pathname } = useLocation();
  const { isAU, isNZ, reverificationRequiredBy } = UserStore.useUserStore;

  const {
    firstName: initialFirstName,
    middleName: initialMiddleName,
    lastName: initialLastName,
    setFirstName: commitFirstName,
    setMiddleName: commitMiddleName,
    setLastName: commitLastName,
    setCurrentStep,
  } = useContext(ReverificationContext);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<NameFields>({
    mode: 'onChange',
    defaultValues: {
      firstName: initialFirstName ?? '',
      middleName: initialMiddleName ?? '',
      lastName: initialLastName ?? '',
    },
    resolver: zodResolver(NameSchema, undefined, { rawValues: true }),
  });

  const { t } = useTranslation('reverifications');

  const onCancel = () => {
    setCurrentStep('REVIEW_DETAILS');
  };

  const onSubmit = (data: NameFields) => {
    avo.confirmDetailsStepCompleted({
      screen: pathname,
      stepId: getAvoEventStepId('UPDATE_NAME'),
      requiredBy: formatAvoDate(reverificationRequiredBy()),
      country: getCountry({ isAU, isNZ }),
    });
    commitFirstName(data.firstName.trim());
    commitMiddleName(data.middleName?.trim());
    commitLastName(data.lastName.trim());
    setCurrentStep('REVIEW_DETAILS');
  };

  return (
    <BaseReverificationStep heading={t('steps.updateName.title')} description={t('steps.updateName.description')}>
      <FlexLayout direction='column' spacing={32}>
        <Controller
          name='firstName'
          control={control}
          render={({ field }) => (
            <FormInput
              id='reverification.firstName'
              label={t('fields.firstName.label')}
              required
              value={field.value}
              onChange={field.onChange}
              error={errors.firstName}
            />
          )}
        />
        <Controller
          name='middleName'
          control={control}
          render={({ field }) => (
            <FormInput
              id='reverification.middleName'
              label={t('fields.middleName.label')}
              hint={t('fields.middleName.hint')}
              value={field.value}
              onChange={field.onChange}
              error={errors.middleName}
            />
          )}
        />
        <Controller
          name='lastName'
          control={control}
          render={({ field }) => (
            <FormInput
              id='reverification.lastName'
              label={t('fields.lastName.label')}
              required
              value={field.value}
              onChange={field.onChange}
              error={errors.lastName}
            />
          )}
        />
      </FlexLayout>
      <FlexLayout direction='row' spacing={12}>
        <Button size='lg' variant='outlined' className='flex-grow' onClick={onCancel}>
          {t('actions.cancel')}
        </Button>
        <Button size='lg' className='flex-grow' onClick={handleSubmit(onSubmit)} disabled={!isValid}>
          {t('actions.update')}
        </Button>
      </FlexLayout>
    </BaseReverificationStep>
  );
});
