import { AddressBrokenUp } from '@shared/api';

export const addressToString = (address: Partial<AddressBrokenUp>) => {
  const { flatNumber, streetNumber, streetName, streetType, postcode } = address || {};

  if (
    !streetNumber ||
    !streetName ||
    !streetType ||
    !postcode ||
    !address.country ||
    (address.country === 'AU' && (!address.suburb || !address.state)) ||
    (address.country === 'NZ' && !address.townCity)
  ) {
    return '';
  }

  const completeAddress = address as AddressBrokenUp;
  const suburbCityState =
    completeAddress.country === 'AU'
      ? [completeAddress.suburb, completeAddress.state].join(' ')
      : [completeAddress.suburb, completeAddress.townCity].filter((v) => !!v).join(' ');

  const unit = `${flatNumber ? `U ${flatNumber} ` : ''}`;
  const street = `${streetNumber} ${streetName} ${streetType}`;
  const suburbCityStatePostcode = `${suburbCityState} ${postcode}`;

  return `${unit}${street}, ${suburbCityStatePostcode}`;
};
