import { ModalTranslations } from '@global-components/Modals/@translations';

import { AssetsTranslations } from '@routes/AssetsList/translations';
import { ProfileAffiliatesTranslations } from '@routes/Profile/subroutes/AffiliateProgram/translations';
import { ProfileReferralsTranslations } from '@routes/Profile/subroutes/ReferAFriend/translations';
import { RewardTranslations } from '@routes/Rewards/translations';
import { OrderTranslations } from '@routes/Transactions/translations';
import { WalletTranslations } from '@routes/Wallet/translations';

import { AuthTranslations } from '@Auth/translations';
import { BundlesTranslations } from '@Bundles/translations';
import { DashboardTranslations } from '@Dashboard/translations';
import { EntitiesTranslations } from '@Entities/translations';
import { LoginTranslations } from '@Login/translations';
import { OnboardingTranslations } from '@Onboarding/translations';
import { ProfileApiKeyTranslations } from '@Profile/subroutes/ApiKeys/translations';
import { EntitySecurityTranslations } from '@Profile/subroutes/Entities/EntitySecurity/translations';
import { ProfileInformationTranslations } from '@Profile/subroutes/Information/translations';
import { ProfileSecurityTranslations } from '@Profile/subroutes/Security/translations';
import { ProfileSettingsTranslations } from '@Profile/subroutes/Settings/translations';
import { ProfileTaxReportsTranslations } from '@Profile/subroutes/TaxReports/translations';
import { ProfileVerificationTranslations } from '@Profile/subroutes/Verification/translations';
import { ProfileTranslations } from '@Profile/translations';
import { RegisterTranslations } from '@Register/translations';
import { TradeTranslations } from '@Trade/translations';

import { ReverificationsTranslations } from '@Reverification/translations';
import i18n from 'i18next';
import { PriceAlertTranslations } from 'src/lib/dynamic-price-alerts/translations';
import { FeatureDiscoveryTranslations } from 'src/lib/feature-discovery/translations';
import { CommonTranslations } from 'src/translations';

if (import.meta.hot) {
  import.meta.hot.accept(async (newModule) => {
    if (!newModule || !i18n.isInitialized) return;

    // Overwrite existing namespace for each language with newly hot loaded JSON contents
    Object.keys(newModule.resources).forEach((lang) => {
      Object.keys(newModule.resources[lang]).forEach((ns) => {
        i18n.addResourceBundle(lang, ns, newModule.resources[lang][ns], true, true);
      });
    });

    // Force re-render of components using translations
    await i18n.changeLanguage(i18n.language);
  });
}

const resources = {
  en: {
    modals: ModalTranslations.en,
    entities: EntitiesTranslations.en,
    assets: AssetsTranslations.en,
    auth: AuthTranslations.en,
    login: LoginTranslations.en,
    register: RegisterTranslations.en,
    orders: OrderTranslations.en,
    dashboard: DashboardTranslations.en,
    onboarding: OnboardingTranslations.en,
    profile: ProfileTranslations.en,
    rewards: RewardTranslations.en,
    reverifications: ReverificationsTranslations.en,
    wallet: WalletTranslations.en,
    'profile.information': ProfileInformationTranslations.en,
    'profile.verification': ProfileVerificationTranslations.en,
    'profile.settings': ProfileSettingsTranslations.en,
    'profile.security': ProfileSecurityTranslations.en,
    'profile.apiKey': ProfileApiKeyTranslations.en,
    'profile.taxreports': ProfileTaxReportsTranslations.en,
    'profile.affiliates': ProfileAffiliatesTranslations.en,
    'profile.referrals': ProfileReferralsTranslations.en,
    'entity.security': EntitySecurityTranslations.en,
    bundles: BundlesTranslations.en,
    trade: TradeTranslations.en,
    common: CommonTranslations.en,
    featureDiscovery: FeatureDiscoveryTranslations.en,
    translation: '',
    priceAlerts: PriceAlertTranslations.en,
  },
  fr: {
    modals: ModalTranslations.fr,
    entities: EntitiesTranslations.fr,
    assets: AssetsTranslations.fr,
    auth: AuthTranslations.fr,
    login: LoginTranslations.fr,
    orders: OrderTranslations.fr,
    register: RegisterTranslations.fr,
    rewards: RewardTranslations.fr,
    bundles: BundlesTranslations.fr,
    dashboard: DashboardTranslations.fr,
    onboarding: OnboardingTranslations.fr,
    profile: ProfileTranslations.fr,
    'profile.information': ProfileInformationTranslations.fr,
    'profile.verification': ProfileVerificationTranslations.fr,
    'profile.settings': ProfileSettingsTranslations.fr,
    'profile.security': ProfileSecurityTranslations.fr,
    'profile.apiKey': ProfileApiKeyTranslations.fr,
    'profile.taxreports': ProfileTaxReportsTranslations.fr,
    'profile.affiliates': ProfileAffiliatesTranslations.fr,
    'profile.referrals': ProfileReferralsTranslations.fr,
    wallet: WalletTranslations.fr,
    trade: TradeTranslations.fr,
    common: CommonTranslations.fr,
    featureDiscovery: FeatureDiscoveryTranslations.fr,
    translation: '',
    priceAlerts: PriceAlertTranslations.fr,
  },
} as const;

export { resources };
