import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';

import { useIntercom } from 'react-use-intercom';

import { Deposit } from './Deposit/Deposit';
import { DepositReceiveProps } from './Deposit/Deposit.types';
import { Receive } from './Receive/Receive';

export const TransferDepositReceive: React.FC<DepositReceiveProps> = ({
  selectedAsset,
  depositType,
  setDepositType,
  setDisabled,
  setOnAction,
  onClose,
  setHideContinue,
}) => {
  const [minimumDepositWarning, setMinimumDepositWarning] = useState<string>();
  const { t } = useTranslation('modals', { keyPrefix: 'transferModal.deposit' });
  const { userProfile } = UserStore.useUserStore;
  const userDepositsDisabled = useCallback(() => userProfile?.accountStatus?.deposits === 0, [userProfile]);
  const { show } = useIntercom();
  const depositsDisabled = !selectedAsset?.deposit_enabled;

  const isFiat = useMemo(() => assetService.isAssetFiat(selectedAsset?.id), [selectedAsset]);
  const isUsersCountryCurrency = useMemo(
    () => userProfile?.countryCurrency?.id === selectedAsset?.id,
    [userProfile, selectedAsset],
  );

  /**
   * @fiatButNotTheirResidentialFiat - If the selected asset is not the user's country currency,
   * !isUsersCountryCurrency - returns true if the selected asset is FIAT and not the user's residential currency.
   * Notification Priority: 1
   */
  const fiatButNotTheirResidentialFiat = selectedAsset && isFiat && !isUsersCountryCurrency;

  /**
   * @assetDepositsBlocked - If the selected asset has deposits disabled at the asset level.
   * depositsDisabled - returns true if the selected asset has deposits disabled on an asset level.
   * !fiatButNotTheirResidentialFiat - returns true if selected asset is their residentual fiat or crypto.
   * Notification Priority: 2
   */
  const assetDepositsBlocked = selectedAsset && depositsDisabled && !fiatButNotTheirResidentialFiat;

  /**
   * @userAccountBlocked - If the current user has deposits disabled.
   * !fiatButNotTheirResidentialFiat - returns true if selected asset is their residentual fiat or crypto.
   * !depositsDisabled - returns true if the selected asset has deposits enabled.
   * userDepositsDisabled() - returns true if the user has deposits disable at an account level.
   * Notification Priority: 3
   */
  const userAccountBlocked =
    selectedAsset && !depositsDisabled && userDepositsDisabled() && !fiatButNotTheirResidentialFiat;

  /**
   * @userIsFullyEnabled - If the current user has deposits disabled.
   * !fiatButNotTheirResidentialFiat - returns true if selected asset is their residentual fiat or crypto.
   * !depositsDisabled - returns true if the selected asset has deposits enabled.
   * userDepositsDisabled() - returns true if the user has deposits disable at an account level.
   * Notification Priority: 3
   */
  const userIsFullyEnabled = selectedAsset && !!selectedAsset.deposit_enabled && !userDepositsDisabled();

  useEffect(() => {
    if (selectedAsset?.customWarningText) {
      setMinimumDepositWarning(selectedAsset.customWarningText.replace('<b>', '').replace('</b>', ''));
    } else {
      setMinimumDepositWarning('');
    }
  }, [selectedAsset]);

  return (
    <FlexLayout spacing={24} className='w-full' direction='column'>
      {minimumDepositWarning && (
        <Notification severity='warning' title='Minimum deposit'>
          {minimumDepositWarning}
        </Notification>
      )}

      {userIsFullyEnabled && (
        <>
          {isFiat ? (
            <>
              {isUsersCountryCurrency && (
                <Deposit
                  asset={selectedAsset}
                  depositType={depositType}
                  setDepositType={setDepositType}
                  setDisabled={setDisabled}
                  setOnAction={setOnAction}
                  onClose={onClose}
                  setHideContinue={setHideContinue}
                />
              )}
            </>
          ) : (
            <Receive asset={selectedAsset} />
          )}
        </>
      )}

      {fiatButNotTheirResidentialFiat && (
        <Notification title={t('depositDisabledTitle', { assetName: selectedAsset.name })} severity='destructive'>
          {t('depositDisabledWrongFIAT', { assetName: selectedAsset.name })}
        </Notification>
      )}

      {assetDepositsBlocked && (
        <Notification title={t('depositDisabledTitle', { assetName: selectedAsset.name })} severity='destructive'>
          {t('depositDisabled', { assetName: selectedAsset.name })}
        </Notification>
      )}

      {userAccountBlocked && (
        <FlexLayout direction='column' spacing={24}>
          <Notification severity='destructive' title={t('userDepositsDisabled.title')}>
            <FlexLayout direction='column' spacing={16}>
              <Body color='secondary'>{t('userDepositsDisabled.message')}</Body>
              <div>
                <Button
                  color='destructive'
                  variant='filled'
                  className='intercom-trigger-bot-deposits-disabled'
                  onClick={show}
                >
                  Contact support
                </Button>
              </div>
            </FlexLayout>
          </Notification>
        </FlexLayout>
      )}
    </FlexLayout>
  );
};
