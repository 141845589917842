import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { CountryDropdown } from '@global-components/Dropdown';
import { AddressInput } from '@global-components/Input/AddressInput/AddressInput';

import { CountriesEnum, SuggestedCountry } from '@shared/enums';
import { UserStore } from '@shared/store';
import { uuid } from '@shared/utils';

import { useAvo } from '@hooks/Avo/useAvo';

import { ReverificationContext } from '@Reverification/Reverification.context';
import { zodResolver } from '@hookform/resolvers/zod';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';

import { BaseReverificationStep } from './components/BaseReverificationStep';
import { AddressSchema, AddressFields } from './schemas/AddressSchema';
import { getAvoEventStepId, formatAvoDate, getCountry } from '../../utils';

export const UpdateAddressStep: React.FC = observer(() => {
  const avo = useAvo();
  const { pathname } = useLocation();
  const { isAU, isNZ, reverificationRequiredBy } = UserStore.useUserStore;

  const sessionUuid = useRef(uuid());
  const { address: initialAddress, setAddress: commitAddress, setCurrentStep } = useContext(ReverificationContext);

  const [country, setCountry] = useState<SuggestedCountry>(CountriesEnum.AU);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<AddressFields>({
    mode: 'onChange',
    defaultValues: {
      address: initialAddress,
    },
    resolver: zodResolver(AddressSchema, undefined, { rawValues: true }),
  });

  const { t } = useTranslation('reverifications');

  const onCancel = () => {
    setCurrentStep('REVIEW_DETAILS');
  };

  const onSubmit = (data: AddressFields) => {
    avo.confirmDetailsStepCompleted({
      screen: pathname,
      stepId: getAvoEventStepId('UPDATE_ADDRESS'),
      requiredBy: formatAvoDate(reverificationRequiredBy()),
      country: getCountry({ isAU, isNZ }),
    });

    const { address } = data;
    commitAddress({
      flatNumber: address.flatNumber?.trim(),
      streetNumber: address.streetNumber.trim(),
      streetName: address.streetName.trim(),
      streetType: address.streetType.trim(),
      postcode: address.postcode.trim(),
      ...(address.country === 'AU'
        ? {
            suburb: address.suburb.trim(),
            state: address.state.trim(),
            country: 'AU',
          }
        : {
            suburb: address.suburb?.trim(),
            townCity: address.townCity.trim(),
            country: 'NZ',
          }),
    });
    setCurrentStep('REVIEW_DETAILS');
  };

  return (
    <BaseReverificationStep heading={t('steps.updateAddress.title')} description={t('steps.updateAddress.description')}>
      <FlexLayout direction='column' spacing={32}>
        <CountryDropdown
          sx={{ marginBottom: 0 }}
          id='residentialCountry'
          label={t('fields.residentialCountry.label')}
          labelFontSize={14}
          value={country}
          onChange={(country) => setCountry(country as SuggestedCountry)}
          onlySuggested
        />
        <Controller
          name='address'
          control={control}
          render={({ field }) => (
            <AddressInput
              required
              sx={{ marginBottom: 0 }}
              sessionUuid={sessionUuid.current}
              country={country}
              onSelectAddress={field.onChange}
              error={!!errors.address}
              helperText={errors.address?.message}
              fieldErrors={errors.address}
              inlineManualInput
            />
          )}
        />
      </FlexLayout>
      <FlexLayout direction='row' spacing={12}>
        <Button size='lg' variant='outlined' className='flex-grow' onClick={onCancel}>
          {t('actions.cancel')}
        </Button>
        <Button size='lg' className='flex-grow' onClick={handleSubmit(onSubmit)} disabled={!isValid}>
          {t('actions.update')}
        </Button>
      </FlexLayout>
    </BaseReverificationStep>
  );
});
